@use "../../../../../stylesheet/00_settings/color" as colors;
@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/00_settings/extra" as extra;

.jira-sidebar {
  width: 496px;
  background-color: map-get(colors.$primary-color, white);
  padding: utils.rem(26) utils.rem(26) 0 utils.rem(26);

  .filter-layout__actions > section {
    padding-right: 0;

    > .input {
      width: 100%;
    }
  }

  > header {
    margin-bottom: utils.bu(1.5);
  }
}

.jira-sidebar-list {
  overflow: scroll;
  height: calc(100vh - 300px);

  &__header {
    display: flex;
    align-items: center;
    margin-left: utils.bu(2);
    margin-bottom: utils.bu(2.5);

    > span {
      margin-right: utils.bu(2);
      line-height: utils.rem(17.86);
    }

    > svg {
      transition: transform .2s ease-out;
    }

    &--open {

      > svg {
        transform: rotate(-180deg);
        transition: transform .2s ease-out;
      }
    }
  }

  &__task-container {
    min-height: 106px;
    padding: utils.bu(2);
    margin-bottom: utils.bu(.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: utils.bu(4.5);
    }

    > p {
      height: 36px;
      font-size: utils.rem(12);
      font-weight: 400;
      line-height: utils.rem(18);
      letter-spacing: utils.rem(1.2);
    }

    > div {
      display: flex;
      gap: utils.bu(1.5);
      align-items: center;

      > img {
        width: 30px;
        height: 30px;
        border-radius: map-get(extra.$border-radius, circular);
        object-fit: cover;
      }

      > span {
        font-size: utils.rem(10);
        font-weight: 400;
        line-height: utils.rem(12);
        letter-spacing: utils.rem(1);
      }
    }
  }
}
