@use "../../../../stylesheet/00_settings/util" as utils;

#project-member-selection {

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .form-action {
      width: unset;
    }
  }
}
