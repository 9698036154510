@use "../../../stylesheet/00_settings/color" as colours;
@use "../../../stylesheet/00_settings/util" as utils;

$loader-width: utils.rem(57);
$loader-orb-size: utils.rem(10);

.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: transparent;
  $block: &;

  &-ellipsis {
    display: flex;
    position: relative;
    width: $loader-width;
    height: $loader-orb-size;
    margin: 0 auto;

    div {
      position: absolute;
      top: 0;
      width: $loader-orb-size;
      height: $loader-orb-size;
      border-radius: 50%;
      background: map-get(colours.$background-color, light);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: utils.rem(4);
        animation: loader-ellipsis-1 .6s infinite;
      }

      &:nth-child(2) {
        left: utils.rem(4);
        animation: loader-ellipsis-2 .6s infinite;
      }

      &:nth-child(3) {
        left: utils.bu(3);
        animation: loader-ellipsis-2 .6s infinite;
      }
    }
  }

  &--dark {
    #{$block}-ellipsis {

      div {
        background: map-get(colours.$background-color, dark);
      }
    }
  }

  &--positioned {
    position: relative;
    top: utils.bu(15);
  }
}

@keyframes loader-ellipsis-1 {

  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loader-ellipsis-2 {

  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(utils.rem(20), 0);
  }
}
