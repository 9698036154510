@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixins;

@mixin image-styling {
  width: utils.bu(4);
  height: utils.bu(4);
  margin-right: utils.bu(2);
  border-radius: map-get(extra.$border-radius, circular);
  object-fit: cover;
  flex-shrink: 0;
}

@mixin content-styling {
  display: flex;
  flex-direction: row;

  & > a > img {
    @include image-styling;
  }

  & > div {
    flex-grow: 1;
    border-radius: utils.rem(9);
    background-color: map-get(colors.$primary-color, light);

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: utils.bu(2);

      & > a > span {
        margin-left: utils.bu(1.5);
        font-size: utils.rem(12);
        font-weight: 450;

        &:hover {
          text-decoration: underline;
        }
      }

      & > div {
        margin-right: utils.bu(1.5);
        cursor: pointer;

        & > .dropdown-menu {

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            min-width: utils.rem(211);
          }
        }
      }
    }

    & > p {
      margin-top: utils.rem(10);
      margin-left: utils.bu(1.5);
      margin-bottom: utils.bu(2);
      font-size: utils.rem(12);
    }
  }
}

@mixin actions-styling {
  margin-left: utils.rem(62);
  margin-top: utils.bu(.5);

  & > button:hover {
    font-weight: 600;
  }

  & > button {
    margin-right: utils.bu(2);
    font-weight: 450;
    font-size: utils.rem(10);
    letter-spacing: utils.rem(1);
  }
}

.nomination-card-comment {
  padding-left: utils.rem(10);
  padding-right: utils.rem(10);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    padding-left: 0;
    padding-right: 0;
  }

  & > #input {
    display: flex;
    flex-direction: row;
    margin-top: utils.bu(3);

    & > img {
      @include image-styling;
    }
  }

  & .comments {
    margin-top: utils.bu(2);
    margin-bottom: utils.bu(1.5);
    cursor: default;

    &__content {

      @include content-styling;
    }

    &__actions {

      & > button:last-of-type {
        color: map-get(colors.$secondary-color, hover);
      }

      &--edit {
        margin-left: 0;
        color: map-get(colors.$primary-color, variant);
      }

      @include actions-styling;
    }

    & .replies {
      margin-bottom: utils.bu(2.5);
      margin-left: utils.rem(50);

      &__content {

        @include content-styling;
      }

      &__actions {
        @include actions-styling;
      }
    }

    &__reply-input {
      display: flex;
      flex-direction: row;
      margin-left: utils.rem(52);
      margin-top: utils.bu(2);
      margin-bottom: utils.bu(1.5);

      & > img {
        @include image-styling;
      }
    }
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-left: utils.bu(8.5);
    margin-right: utils.bu(3);
  }
}
