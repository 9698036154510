@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../../stylesheet/00_settings/config" as config;

.project-form {
  max-width: utils.rem(746);

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: utils.bu(1);

    > .form-action {
      width: unset;
    }
  }

  > form {

    > fieldset {

      > .form-row {
        justify-content: flex-start;
      }

      > .form-row > div:last-of-type {
        width: 100%;
      }
    }

    > fieldset > div:nth-of-type(4) > div:last-of-type {

      @include mixins.breakpoint(medium) {
        display: none;
      }
    }

    > fieldset:first-of-type {
      padding: utils.rem(65) utils.bu(4) utils.bu(7);
      background-color: map-get(colors.$secondary-color, light);

      @include mixins.breakpoint(medium) {
        padding: utils.bu(4);
      }

      > div:first-of-type,
      > div:nth-of-type(3),
      > div:nth-of-type(4) {
        margin-bottom: utils.bu(4);
        display: flex;
        flex-direction: column;

        @include mixins.breakpoint(medium) {
          flex-direction: row;
        }
      }

      > div:nth-of-type(2) {
        margin-bottom: utils.bu(4);
      }
    }
  }

  &__billable {
    width: 100%;
    display: none;

    @include mixins.breakpoint(medium) {
      display: inline;
      margin-left: utils.bu(7);
    }

    > div {
      display: flex;
      margin-top: utils.bu(1);
      gap: utils.bu(3);

      > .radio-selector {
        gap: utils.bu(1);
      }
    }
  }
}
