@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/01_tools/mixin" as mixins;
@use "../../stylesheet/00_settings/config" as config;

.kudos-management-dashboard {
  height: 100%;
  padding-left: utils.bu(4);
  padding-right: utils.bu(4);
  padding-top: utils.bu(5);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    padding: utils.bu(10) utils.bu(16) utils.bu(10) utils.bu(13);
  }

  &__cards {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.bu(10);
      flex-direction: row;
    }

    & > a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mixins.card('grey');
    }

    & > figure {
      font-size: utils.rem(14);
      margin: 0 0 utils.bu(12);
      font-weight: 400;
      @include mixins.card('dark');

      & > figcaption {
        margin-top: utils.rem(40);
        display: flex;
        justify-content: flex-end;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        font-size: utils.rem(16);
        line-height: 200%;
        letter-spacing: utils.rem(1.6);

        & > figcaption {
          margin-top: utils.rem(101);
          text-align: end;
        }
      }
    }
  }

  &__counter {

    & > span:first-child {
      font-size: utils.rem(56);
      margin-right: utils.bu(1);
      @include mixins.set-font-family("Neue Haas Grotesk Display Pro");
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      align-self: flex-end;
      align-items: start;
      padding-top: utils.bu(8);

      & > span:first-child {
        margin-right: utils.bu(2);
        font-size: utils.rem(96);
      }
    }
  }
}
