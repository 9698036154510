// all typography styles
@use "../00_settings/util" as *;

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
small,
label {
  font-weight: 400;
  letter-spacing: rem(1.6);
}

h1 {
  font-size: rem(61);
}

h2 {
  font-size: rem(49);
}

h3 {
  font-size: rem(39);
}

h4 {
  font-size: rem(31);
}

h5 {
  font-size: rem(25);
}

h6 {
  font-size: rem(20);
}

p,
a {
  font-size: rem(16);
}

small {
  font-size: rem(13);
}

label {
  font-size: rem(10);
}

strong {
  font-weight: 450;
}
