@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/00_settings/util" as utils;

#task-list {
  max-width: 746px;
  max-height: 535px;
  padding: utils.bu(4);
  background-color: map-get(colors.$background-color, light-grey);

  > h1 {
    font-size: utils.rem(25);
    margin-bottom: utils.rem(36);
  }

  > fieldset {
    display: flex;
    border: solid 1px map-get(colors.$border-color, primary);
    height: 48px;
    padding-left: utils.bu(2);
    padding-right: utils.bu(2);

    > input {
      border: none;
      width: 100%;
      height: 100%;
      color: map-get(colors.$secondary-color, secondary);
      font-size: utils.rem(16);

      @include mixins.set-font-family("Neue Haas Grotesk Display Pro");
    }

    > button > svg {
      @include mixins.opacity-hover;
    }
  }

  > ul {
    max-height: utils.rem(312);
    overflow-x: scroll;

    > li {
      border: 1px solid map-get(colors.$border-color, tertiary);
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: utils.bu(2);
      padding-right: utils.bu(2);
      margin-top: utils.bu(.5);
      justify-content: space-between;

      > button > svg {
        @include mixins.opacity-hover;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .form-action {
      width: unset;
    }
  }
}
