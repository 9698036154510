@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.profile-details {

  &-tabs {
    width: utils.rem(273);
    border: utils.rem(1) solid map-get(colors.$border-color, primary);

    &__header {
      display: flex;
      justify-content: space-between;
      margin: utils.rem(30) utils.rem(35) utils.rem(20) utils.rem(36);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-left: utils.rem(60);
        margin-right: utils.rem(50);
      }

      & > button {
        display: flex;
        align-items: center;

        & > svg {
          margin-right: utils.rem(10);
          @include mixins.opacity-hover;
        }
      }

      & > .dropdown__popper--open {
        width: utils.rem(181);

        & > .dropdown-menu {
          min-width: utils.rem(181);
        }
      }

      .dropdown-menu > .dropdown-menu__item > button {
        padding: utils.rem(14) 0 utils.rem(14) utils.bu(3);
        font-size: utils.rem(12);
        letter-spacing: utils.rem(1.6);
      }

      & > span {
        font-size: utils.bu(2);
        letter-spacing: utils.rem(1.6);
      }
    }
  }

  &-tab {
    border-bottom: utils.rem(1) solid map-get(colors.$border-color, primary);
    font-size: utils.rem(12);
    letter-spacing: utils.rem(1.6);
    color: map-get(colors.$secondary-color, secondary);
    font-weight: 400;

    & > button {
      padding: utils.bu(2) 0 utils.bu(2) utils.bu(3);
      height: 100%;
      width: 100%;
      text-align: start;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &--enabled {
      cursor: pointer;

      &:hover {
        background-color: map-get(colors.$background-color, white);
      }
    }

    &--selected {
      background-color: map-get(colors.$background-color, white);
      font-weight: 450;
    }
  }
}
