@use "../../../stylesheet/00_settings/util" as utils;

.popular-categories-card {
  width: 100%;
  font-weight: 400;
  letter-spacing: .1em;

  & > .card {
    width: 100%;
    position: relative;
    padding: utils.rem(20);
    display: flex;
    flex-direction: column;
    margin-bottom: utils.rem(22);
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    & > h1 {
      width: 60%;
      font-weight: 400;
      font-size: utils.rem(12);
      line-height: 150%;
    }
  }

  &__content {
    display: flex;
    margin-top: utils.rem(28);
  }

  &__categories {
    font-size: utils.rem(12);
    font-weight: 400;
    min-width: 55%;

    &--active {
      font-weight: 500;
    }
  }

  &__chart {
    min-width: utils.bu(23);
    min-height: utils.bu(23);
  }
}
