@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/00_settings/config" as config;

.select {
  width: 100%;

  input:hover {
    cursor: pointer;
  }

  .dropdown {

    &-menu {
      max-height: utils.rem(220);
      overflow-y: scroll;
      padding: utils.rem(6) 0;
      background-color: map-get(colors.$background-color, white);
      border-color: map-get(colors.$border-color, secondary);

      &__item {
        padding: 0;
        border: none;
        background-color: map-get(colors.$background-color, white);

        &:hover {
          background-color: map-get(colors.$background-color, light);
        }

        & > button {
          width: 100%;
          padding: utils.rem(18) utils.bu(3);
        }
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    letter-spacing: utils.rem(1.6);

    &--end {
      flex-direction: row-reverse;

      & > span {
        flex: 1;
      }

      &-ellipse {
        margin: 0;
      }
    }

    &-ellipse {
      margin: 0 utils.rem(14) 0 0;
      width: utils.bu(2);
      height: utils.bu(2);
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
      border-radius: map-get(extra.$border-radius, circular);
      cursor: pointer;

      &--checked::after {
        top: utils.rem(1);
        width: utils.rem(12);
        height: utils.rem(12);
        margin: auto;
        content: '';
        display: block;
        background-color: map-get(colors.$background-color, dark);
        position: relative;
        visibility: visible;
        border-radius: map-get(extra.$border-radius, circular);
        border: none;
      }
    }
  }

  &--open {

    .input__icon {
      padding-right: 0;
      padding-left: utils.rem(12);
      transform: rotate(180deg);
    }
  }

  &--small {
    width: fit-content;

    .input {
      width: fit-content;
      position: unset;

      input {
        padding-left: 0;
        height: unset;
        color: map-get(colors.$primary-color, primary);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          color: map-get(colors.$primary-color, black);
        }
      }
    }

    .input__icon {
      padding-right: 0;
      padding-left: 0;
      transform: rotate(-90deg);

      svg {
        width: utils.rem(7);
      }
    }
  }
}
