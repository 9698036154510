@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/00_settings/extra" as extra;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.remove-user {

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-left: utils.rem(44);
    margin-right: utils.rem(44);
  }

  & > p {
    font-size: utils.bu(2);
    letter-spacing: utils.rem(1.6);
    line-height: 200%;
    text-align: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(14);
    }

    & > span {
      font-style: italic;
    }
  }

  &__actions {
    margin-top: utils.bu(3);
    margin-bottom: utils.rem(28);
    align-items: center;
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.bu(5);
      margin-bottom: utils.rem(28);
      justify-content: space-between;
      flex-direction: row;
    }

    & > button {
      margin-bottom: utils.bu(3);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-bottom: utils.rem(0);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
