@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/06_components/components" as component;

.leave-category-list {
  top: 135px;
  margin-left: calc(#{component.$scheduling-settings-menu} + #{utils.bu(7)});
  position: relative;
  margin-right: utils.bu(12.5);

  &__table {
    background-color: map-get(colors.$background-color, light-grey);
  }
}
