@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/00_settings/config" as config;
@use "../../stylesheet/01_tools/mixin" as mixins;

.tabs {
  width: fit-content;

  & > li {
    padding: utils.rem(6) utils.rem(2) utils.bu(1);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding-left: utils.rem(6);
      padding-right: utils.rem(6);
      font-size: utils.rem(13);
    }
  }

  &__tab {
    display: inline;
    text-align: center;
    font-size: utils.rem(11);
    margin-right: utils.rem(10);
    letter-spacing: utils.rem(1.6);
    line-height: 150%;
    cursor: pointer;

    &--selected {
      font-weight: 450;
      border-bottom: utils.rem(1) solid map-get(colors.$primary-color, black);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      font-size: utils.bu(2);
      width: 50%;
    }
  }
}
