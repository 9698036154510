@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.give-kudos-title {
  display: flex;
  align-items: flex-end;

  & > h1 {
    font-size: utils.rem(20);
    letter-spacing: utils.rem(2);
    flex: 1;
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    align-items: center;

    & > h1 {
      font-size: utils.rem(25);
      letter-spacing: utils.rem(2.5);
    }

    & > span:nth-child(2) {
      padding: utils.rem(6) 0;
      font-size: utils.rem(31);
    }

    & > span:last-child {
      margin-left: utils.rem(11);
      margin-right: utils.bu(3);
      font-size: utils.bu(2);
    }
  }

  &__remaining {
    align-items: end;
    font-size: utils.rem(20);
  }

  &__subtitle {
    font-size: utils.rem(14);
    letter-spacing: utils.rem(.98);
    margin-left: utils.rem(4);
  }
}
