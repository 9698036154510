@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;

.number-picker {
  display: flex;
  flex-direction: column;

  &__field-container {
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding-right: utils.bu(3.5);
    }

    & > fieldset {
      position: relative;
      display: flex;
      height: utils.rem(30);
      width: utils.rem(130);
    }
  }

  &__field-container::-webkit-scrollbar {
    display: none;
  }

  &__input {
    @include mixins.input-content;

    padding: 0 utils.bu(5);
    text-align: center;
    width: utils.rem(130);
    height: utils.rem(30);
    border: utils.rem(1) solid map-get(colors.$border-color, primary);
    font-size: utils.bu(2);
    letter-spacing: utils.bu(.2);

    /* Remove Spinners on Chrome, Safari, Edge, Opera */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Remove Spinners on Firefox */

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &--focused {
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
    }
  }

  &__label {
    font-size: utils.rem(12);
    margin-bottom: utils.bu(1);
    color: map-get(colors.$secondary-color, secondary);
    letter-spacing: utils.rem(1.2);
  }

  &__increment {
    position: absolute;
    top: 0;
    width: utils.bu(5);
    height: utils.rem(30);
    border: utils.rem(1) solid map-get(colors.$border-color, primary);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    @include mixins.transition(background-color, .2s);

    & > svg {
      width: utils.rem(18);
      height: utils.rem(18);
    }

    &:last-child {
      left: auto;
      right: 0;
    }

    &--focused {
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
    }

    &:hover {
      background-color: map-get(colors.$background-color, light);
    }
  }
}
