@use "../../../stylesheet/00_settings/color" as color;
@use "../../../stylesheet/00_settings/util" as util;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixin;

$pagination-font-size: util.rem(15);
$pagination-control-size: util.bu(4);
$pagination-control-icon-size: util.rem(12);

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: util.rem(10);

  &-control {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $pagination-control-size;
    height: $pagination-control-size;
    line-height: 1;
    border-radius: map-get(extra.$border-radius, small);
    color: map-get(color.$primary-color, black);
    font-size: $pagination-font-size;
    background-color: map-get(color.$background-color, white);

    & + & {
      margin-left: util.bu(1);
    }

    &:first-child,
    &:last-child {
      background-color: transparent;
    }

    &:not(&[disabled=""]):hover {
      border: util.rem(1) solid map-get(color.$border-color, secondary);
      border-radius: 0;
      color: map-get(color.$primary-color, primary);
      background-color: map-get(color.$background-color, table-hover);
      @include mixin.transition(background-color, font-weight);
    }

    &[disabled=""] {
      opacity: .5;
      cursor: auto;
    }

    &--active {
      border: util.rem(1) solid map-get(color.$border-color, secondary);
      border-radius: 0;
      @include mixin.transition(background-color, font-weight);

      &:hover {
        color: map-get(color.$primary-color, white);
      }
    }

    &__icon {
      height: $pagination-control-icon-size;
    }
  }
}
