@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: utils.rem(204);
  height: utils.bu(6);
  background-color: map-get(colors.$background-color, dark);
  color: map-get(colors.$primary-color, light);
  font-size: utils.rem(13);
  letter-spacing: utils.rem(1.6);
  $block: &;
  @include mixins.transition(all, .2s, ease-in);

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    & > #{$block}__icon {
      position: absolute;
      right: utils.rem(28);
    }

    &--left {
      flex-direction: row-reverse;

      & > #{$block}__icon {
        left: utils.rem(28);
        right: unset;
      }
    }
  }

  &:hover {
    background-color: map-get(colors.$background-color, dark-hover);
  }

  &--secondary {
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);
    background-color: transparent;
    color: map-get(colors.$primary-color, primary);

    &:hover {
      border-color: map-get(colors.$secondary-color, secondary);
      color: map-get(colors.$secondary-color, secondary);
      background-color: transparent;
    }
  }

  &--borderless {
    border: none;
  }

  &--large {
    width: utils.bu(35);
  }

  &--small {
    width: utils.bu(21);
  }

  &--fit {
    flex: 1;
  }

  &--text {
    height: unset;
    width: unset;
    background-color: unset;
    border: unset;
    color: map-get(colors.$primary-color, primary);

    &:hover {
      padding-bottom: utils.rem(5);
      background-color: unset;
      color: map-get(colors.$secondary-color, secondary);
    }

    & > #{$block}__content {

      &:hover {
        text-decoration: underline;
      }

      & > #{$block}__icon {
        position: relative;
        right: unset;
        margin-left: utils.rem(13);
      }

      &--left {

        & > #{$block}__icon {
          right: unset;
          left: unset;
          margin-right: utils.rem(13);
        }
      }
    }
  }

  &--disabled {
    cursor: default;
    background-color: rgba(map-get(colors.$secondary-color, secondary), .2);
    color: rgba(map-get(colors.$secondary-color, secondary), .3);

    &:hover {
      background-color: rgba(map-get(colors.$secondary-color, secondary), .2);
    }

    &#{$block}--secondary {
      border: utils.rem(1) solid rgba(map-get(colors.$border-color, secondary), .2);
      background-color: transparent;
      color: rgba(map-get(colors.$border-color, secondary), .2);
    }
  }
}
