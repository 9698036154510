@use "../../../stylesheet/00_settings/color" as color;
@use "../../../stylesheet/00_settings/util" as util;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixin;

.no-results {
  padding-top: util.rem(60);
  padding-bottom: util.rem(60);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  border: util.rem(.5) solid map-get(color.$border-color, alternate);

  > h3 {
    font-size: util.rem(18);
    margin-bottom: util.rem(24);
    line-height: 120%;

    @include mixin.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: util.rem(39);
      margin-bottom: util.bu(4);
      letter-spacing: util.rem(1.6);
    }
  }

  > p {
    font-size: util.rem(10);
    line-height: 200%;
    letter-spacing: util.rem(1.6);
    margin-bottom: util.rem(30);

    @include mixin.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-bottom: util.rem(27);
      width: util.rem(337);
      font-size: util.bu(2);
    }
  }

  &__tags {
    display: flex;

    & > .tag + .tag {
      margin-left: util.rem(12);
    }
  }
}
