@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/06_components/components" as component;

.scheduling-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: utils.rem(120);
  align-items: center;
  height: calc(100% - #{utils.rem(component.$header-height)});
  padding: utils.bu(3) utils.bu(2.5);
  left: 0;
  position: fixed;
  z-index: 2;

  &__top,
  &__bottom {
    width: utils.rem(80);

    > menu {
      display: flex;
      flex-direction: column;
      align-items: center;

      > li {

        > a {
          flex-direction: column;
          display: flex;
          align-items: center;
          font-size: utils.rem(10);

          > span {
            opacity: 0;
            margin-top: utils.bu(.5);
            @include mixins.transition(opacity, .5s, ease-in-out);
          }

          > svg {
            width: utils.rem(24);
            height: utils.rem(24);
          }

          &:hover {

            > span {
              opacity: 1;
            }
          }
        }

        &.nav-menu-item--active {

          > a > svg,
          span {
            color: map-get(colors.$primary-color, variant);
          }
        }
      }
    }

    > hr {
      border: utils.rem(.41) solid map-get(colors.$border-color, secondary);
      display: block;
    }
  }

  &__top > menu > li > a {
    margin-bottom: utils.bu(5);
  }

  &__bottom > menu > li > a {
    margin-top: utils.bu(5);
  }
}
