@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.kudos-leaderboard {
  margin-bottom: utils.rem(22);
  $block: &;

  & > .card {
    position: relative;
    padding: utils.rem(20);

    & > h1 {
      font-size: utils.rem(20);
      margin-bottom: utils.rem(20);
      margin-top: utils.rem(20);
    }

    & > div {
      display: flex;
      margin-bottom: utils.bu(1);
      align-items: center;

      &#{$block}__empty-message {
        display: block;
        margin-top: utils.rem(52);
        margin-bottom: utils.rem(68);

        & > p {
          font-size: utils.rem(10);
          line-height: 120%;

          &:nth-child(2) {
            margin-top: utils.rem(20);
          }
        }
      }
    }

    & > div > a > span:first-of-type {
      font-weight: 400;
      letter-spacing: utils.rem(1.2);
      font-size: utils.rem(12);

      &:hover {
        text-decoration: underline;
      }
    }

    & > div:nth-of-type(n + 4) {
      display: none;
    }

    & > div > span:last-of-type {
      font-weight: 400;
      letter-spacing: utils.rem(1.2);
      font-size: utils.rem(12);
      margin-left: auto;
      margin-top: utils.rem(7);
    }

    & > div > img {
      width: utils.bu(4);
      height: utils.bu(4);
      margin-right: utils.bu(2);
      border-radius: map-get(extra.$border-radius, circular);
      object-fit: cover;
    }
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

    & > .card > div:nth-of-type(n + 4) {
      display: flex;
    }
  }
}
