@use "../../stylesheet/06_components/components" as component;
@use "../../stylesheet/00_settings/util" as utils;

main {
  position: relative;
  top: utils.rem(component.$header-height);
  height: 100%;

  &.with-side-menu {
    margin-left: utils.bu(5);
  }
}
