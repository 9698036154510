@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;

.kudos-dashboard {
  margin-top: utils.bu(5);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    display: flex;
    margin-left: utils.bu(13);
    margin-right: utils.rem(205);
    margin-top: utils.bu(10);
    position: fixed;
    width: calc(100vw - #{utils.rem(309)});
  }

  &__mobile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: utils.rem(22);
    margin-left: utils.rem(20);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-right: utils.rem(180);
      margin-left: utils.rem(180);
    }

    & > button {
      width: 100%;
      margin-top: utils.bu(3);
      margin-bottom: utils.bu(3);
    }
  }

  &__mobile-timeline {

    & > aside {
      margin-left: utils.rem(20);
      margin-right: utils.rem(20);
    }
  }

  &__timeline {
    overflow-y: scroll;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-basis: 100%;
      overflow-y: unset;
    }

    & > nav {
      margin-bottom: utils.bu(2);
    }
  }

  & > aside {
    width: 100%;
    overflow-y: scroll;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-basis: 51.47%;
      overflow-y: unset;
      max-width: utils.rem(350);
    }

    & > button {
      width: 100%;
      margin-right: utils.rem(10);
    }
  }

  &__scroll {
    margin: utils.rem(78) utils.rem(28) 0;
  }
}
