@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/00_settings/color" as colours;

.scheduling-calendar {
  $block: &;

  &__week {
    display: flex;
    overflow-x: auto;
    width: 100vw;
    position: relative;

    > div {

      > div {
        position: relative;

        > div {

          &:last-of-type > #{$block}__tasks {
            border-bottom: 1px solid map-get(colours.$secondary-color, variant);
          }

          > #{$block}__tasks {
            cursor: pointer;
            border-right: 1px solid map-get(colours.$secondary-color, variant);
            border-top: 1px solid map-get(colours.$secondary-color, variant);
            min-height: 216px;
            gap: utils.rem(10);
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            width: 210px;

            &--personal {
              height: 100vh;
            }

            &--weekend {
              background-color: map-get(colours.$background-color, contact-action);
            }

            .scheduling-allocation:not(.scheduling-allocation--leave) {

              &:first-of-type {
                margin-top: utils.rem(10);
              }

              &:last-of-type {
                margin-bottom: utils.rem(10);
              }
            }
          }
        }
      }

      > #{$block}__day {
        display: flex;
        flex-direction: column;
        width: 210px;
        height: 88px;
        align-items: center;
        position: sticky;
        top: 0;

        &--weekend {
          opacity: .5;
        }

        &--today {

          > span {
            color: map-get(colours.$border-color, variant);
          }
        }

        > span:first-of-type {
          font-size: utils.rem(39);
          line-height: utils.rem(46.8);
          letter-spacing: utils.rem(3.9);
        }

        > span:last-of-type {
          font-size: utils.rem(16);
          font-weight: 400;
          line-height: utils.rem(32);
          letter-spacing: utils.rem(1.6);
        }
      }
    }
  }

  &__overtime {
    position: absolute;
    z-index: 0;
    top: 216px;
    width: 100%;
    border-top: 1px solid map-get(colours.$border-color, overtime);
    background-color: map-get(colours.$background-color, overtime);
  }
}
