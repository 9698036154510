@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: utils.rem(22);
    font-weight: 400;
    margin: utils.bu(8) 0 utils.rem(53) 0;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin: utils.rem(60) utils.bu(4) utils.bu(5) utils.rem(33);
      font-size: utils.rem(39);
      font-weight: 450;
    }
  }

  & > small {
    width: utils.rem(291);
    height: utils.rem(46);
    font-size: utils.rem(12);
    letter-spacing: utils.rem(1.6);
    text-align: center;
    margin-bottom: utils.rem(12);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(351);
    }
  }

  & > div,
  & > .button {
    margin-top: utils.rem(28);
  }
}
