@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/06_components/components" as component;

.kudos-management {
  position: relative;
  width: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    top: utils.rem(component.$header-and-breadcrumb-height);
    padding-left: utils.bu(15);
    padding-right: utils.bu(11);
  }

  &__loader {
    display: flex;
    height: 100vh;
  }

  .filter-layout {
    padding-top: utils.bu(4);
    padding-bottom: utils.bu(4);

    &__actions {
      margin-left: utils.bu(4);
      width: calc(100% - 2 * #{utils.rem(32)});

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-bottom: utils.rem(50);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-left: 0;
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      padding: 0;
    }

    & > .button {
      width: calc(100% - 2 * #{utils.rem(32)});
      margin-left: utils.bu(4);
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    margin-top: utils.bu(3);

    & > div {
      width: 100%;
      background: map-get(colors.$primary-color, white);
      margin-bottom: utils.bu(5);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.bu(4);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
      margin-top: 0;

      & > div {
        width: utils.rem(378);
        margin-right: utils.bu(4);
      }
    }
  }
}
