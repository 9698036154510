@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;

.user-checkbox-table {
  background-color: map-get(colors.$background-color, light-grey);
  max-height: 43vh;
  overflow-y: auto;

  > .table > .table__body > .table-row > td.table-cell:first-child {
    width: utils.rem(116);
  }
}
