@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.user-management {
  top: utils.rem(20);
  position: relative;

  .filter-layout__actions {
    padding-left: utils.rem(35);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      padding-left: 0;
    }
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    top: utils.rem(135);
    position: relative;
    padding: 0 utils.rem(50);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    padding: 0 utils.bu(15) 0 utils.rem(121);
  }

  &-mobile-cards {
    padding-bottom: utils.rem(100);

    &__wrapper {
      display: flex;
      flex-direction: column;

      & > p {
        margin-bottom: utils.bu(1);
        font-size: utils.bu(2);
      }

      .input input {
        padding: 0;
        width: utils.bu(10);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          width: utils.rem(125);
        }
      }
    }

    & > .mobile-card {

      & > img {
        width: utils.rem(53);
        height: utils.rem(53);
        object-fit: cover;
        border-radius: map-get(extra.$border-radius, circular);
        margin-left: utils.bu(4);
        margin-right: utils.rem(21);
      }
    }
  }

  &__mobile-user-wrapper {
    display: flex;
    flex-direction: column;

    & > p {
      margin-bottom: utils.bu(1);
      font-size: utils.bu(2);
    }

    .input input {
      padding: 0;
    }
  }

  &__mobile-icon-wrapper {
    margin-left: auto;
    margin-right: utils.rem(55);
  }

  &__mobile-action {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &::before {
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      content: '';
      background:
        linear-gradient(
          180deg,
          rgba(map-get(colors.$background-color, light-grey), 0) 1.42%,
          map-get(colors.$background-color, light-grey) 100%,
          rgba(map-get(colors.$background-color, light-grey), .5) 100%
        );
      backdrop-filter: blur(utils.rem(.5));

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        display: none;
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      position: relative;
      bottom: unset;
      margin-top: utils.bu(5);
      padding: 0;
      justify-content: unset;
      background: unset;
      backdrop-filter: unset;
    }

    & > .button {
      position: relative;
      z-index: 2;
      width: utils.bu(8);
      height: utils.bu(8);
      margin-right: utils.rem(4);
      margin-bottom: utils.bu(4);
      border-radius: map-get(extra.$border-radius, circular);

      & > .svg {
        color: map-get(colors.$secondary-color, light);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        width: utils.rem(204);
        height: utils.bu(6);
      }
    }
  }

  &__table {
    max-height: calc(100vh - #{utils.rem(300)});
    overflow-y: scroll;
    padding-bottom: utils.rem(113);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
