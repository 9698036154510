@use "../../../stylesheet/00_settings/util" as utils;

#mobile-back-action {
  display: none;
}

.add-category {

  &__modal {
    margin: utils.rem(28);
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: utils.rem(16);
    font-weight: 400;
    letter-spacing: utils.rem(2.5);
    margin-bottom: utils.bu(4);
    margin-top: utils.rem(12);
  }
}

.modal-layout.add-category {
  padding: utils.rem(36);
  width: utils.rem(539);

  &__header {
    margin-bottom: utils.rem(28);
  }

  &__header-title {
    margin-left: 0;
    font-size: utils.rem(25);
    letter-spacing: utils.rem(2.5);
  }
}
