@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;

.scroll-indicator {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: utils.bu(5);
  align-items: center;
  padding: utils.bu(2) 0;

  &__label {
    height: utils.rem(50);
    display: flex;
    align-items: center;
    margin-bottom: utils.bu(2);

    & > span {
      transform: rotate(-90deg);
      font-size: utils.rem(20);
    }

    &--small {
      height: utils.rem(30);

      & > span {
        font-size: utils.rem(12);
      }
    }
  }

  & > span:last-child {
    margin-left: utils.rem(1);
    border-left: solid 1px map-get(colors.$border-color, variant);
    width: utils.rem(1);
    flex: 1;
  }
}
