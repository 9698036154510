@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.input {
  position: relative;
  width: 100%;
  $block: &;

  &__wrapper {
    @include mixins.input-container;
  }

  input {
    @include mixins.input-content;
  }

  &--filled {
    #{$block}__wrapper {
      border: utils.rem(1) solid rgba(map-get(colors.$border-color, secondary), .8);
    }
  }

  &--active {
    #{$block}__wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
    }
  }

  &--error {
    #{$block}__wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, error);
    }

    input,
    input:focus {
      color: map-get(colors.$border-color, error);
      @include mixins.placeholder(map-get(colors.$primary-color, error));
    }
  }

  &--disabled {
    #{$block}__wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, primary);
    }

    input {
      color: map-get(colors.$secondary-color, disabled);
      @include mixins.placeholder(map-get(colors.$secondary-color, disabled));
    }
  }

  &--borderless {

    #{$block}__wrapper {
      border: none;
    }
  }
}
