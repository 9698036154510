@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.radio-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > label:first-of-type {
    font-size: utils.rem(12);
    font-weight: 400;
    word-break: break-all;
  }

  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    color: map-get(colors.$secondary-color, alternate);
    border: solid utils.rem(1) map-get(colors.$primary-color, primary);
    position: relative;
    flex-shrink: 0;
  }

  &__group {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    display: none;
  }

  &__checked {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: map-get(colors.$primary-color, primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
