@use "../../../stylesheet/00_settings/util" as util;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.kudos-timeline {
  width: 100%;
  padding-top: util.bu(4);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    // 168px is the starting position of the this component from the top.
    height: calc(100vh - #{util.bu(21)});
    padding-bottom: util.bu(3);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__empty-message {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > h6 {
      margin-bottom: util.bu(5);
      font-size: util.rem(20);
      letter-spacing: util.rem(2);
    }

    & > p {
      font-size: util.rem(10);
      line-height: 120%;
      letter-spacing: util.rem(1);

      &:nth-child(3) {
        margin-bottom: util.bu(5);
      }
    }

    & > .button {
      width: util.rem(204);
    }
  }
}
