@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/extra" as extra;

.scheduling-timesheet-form {

  &__actions {
    display: flex;
    gap: utils.bu(3);
    padding-top: utils.bu(5.5);
  }
}
