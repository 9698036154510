@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.unread-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: utils.rem(14);
  width: utils.rem(14);
  border-radius: 50%;
  color: map-get(colors.$secondary-color, alternate);
  background-color: map-get(colors.$secondary-color, alternate);

  &__read {
    height: utils.bu(1);
    width: utils.bu(1);
    border-radius: 50%;
    background-color: map-get(colors.$primary-color, variant);
  }
}
