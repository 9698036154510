@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;

.input__error {
  display: block;
  font-size: utils.rem(12);
  color: map-get(colors.$primary-color, error);
  margin-top: utils.bu(1);
  padding-left: utils.rem(19);
}
