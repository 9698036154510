@use '../../../../stylesheet/00_settings/color' as color;
@use '../../../../stylesheet/00_settings/util' as utils;

$bg: map-get(color.$background-color, light);

div#skeleton {
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 100%;

  &.below-crumb {
    top: utils.bu(20);
  }

  > div.placeholder {
    position: absolute;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image:
        linear-gradient(
          90deg,
          rgba($bg, 0) 0,
          rgba($bg, .2) 20%,
          rgba($bg, .5) 60%,
          rgba($bg, 0)
        );
      animation: shimmer 4s infinite;
      content: '';
    }

    & > div.child {
      position: relative;
    }
  }

  > *:not(.placeholder) {
    opacity: 0;
  }

  @keyframes shimmer {

    100% {
      transform: translateX(100%);
    }
  }
}
