@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;

.category-tooltip {
  display: flex;
  align-content: stretch;

  &__items {
    display: flex;
    flex-direction: column;
    margin: utils.rem(20);

    & > span {

      &:not(:last-child) {
        margin-bottom: utils.rem(21);
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-flow: row wrap;

      & > span {
        width: utils.rem(170);

        &:not(:last-child) {
          margin-bottom: utils.rem(21);
          margin-right: utils.bu(8);
        }
      }
    }
  }

  & > div:last-child {
    margin-right: utils.bu(1);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      display: none;
    }
  }
}
