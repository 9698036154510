@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;

.mobile-card {
  display: flex;
  align-items: center;
  height: utils.bu(11);
  background-color: map-get(colors.$secondary-color, light);
  margin-bottom: utils.rem(12);

  & > button {
    margin-right: utils.rem(30);
  }
}
