@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/00_settings/config" as config;
@use "../../stylesheet/00_settings/extra" as extra;
@use "../../stylesheet/01_tools/mixin" as mixins;

.notifications {
  width: 100%;
  background-color: map-get(colors.$background-color, light);
  margin-top: utils.bu(3);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    border: utils.rem(1) solid map-get(colors.$border-color, primary);
    min-width: utils.rem(509);
    padding: utils.bu(5) 0 0;
  }

  &__list {
    overflow-y: auto;
    margin-top: utils.rem(7);
    padding-bottom: utils.bu(4);
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      max-height: utils.rem(420);
    }
  }

  &__list::-webkit-scrollbar {
    display: none;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    padding-left: utils.rem(30);
    padding-right: utils.bu(2.5);

    & > .tabs__tab:first-child {
      padding-right: utils.rem(28);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      justify-content: flex-start;
      padding-left: utils.rem(41);
      padding-right: utils.bu(3.5);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: utils.rem(42);
    margin-top: utils.bu(2);
    padding-left: utils.rem(30);
    padding-right: utils.bu(2.5);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-bottom: utils.bu(4);
      padding-left: utils.rem(41);
      padding-right: utils.bu(3.5);
    }

    & > button:last-of-type {
      font-size: utils.rem(14);
      font-weight: 500;
      line-height: 200%;
      letter-spacing: utils.rem(1.4);
      color: map-get(colors.$primary-color, blue);
      cursor: pointer;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: utils.bu(2);
        letter-spacing: utils.rem(1.6);
      }
    }
  }

  &__header-wrapper {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: utils.rem(5);
      @include mixins.opacity-hover;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        display: none;
      }
    }

    & > h5 {
      font-size: utils.bu(2);
      letter-spacing: utils.rem(1.6);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: utils.rem(25);
        letter-spacing: utils.rem(2.5);
      }
    }
  }
}
