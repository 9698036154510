@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.filters {
  font-size: utils.rem(14);
  letter-spacing: utils.rem(1.6);
  font-weight: 450;

  &__tree {
    margin-top: utils.bu(2.5);

    &-option {
      cursor: pointer;
      user-select: none;
      margin-bottom: utils.bu(2.5);

      &--active:last-of-type {
        margin-bottom: 0;

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-bottom: 0;
        }
      }

      & > button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: utils.bu(2);
        padding-right: utils.bu(2.5);

        > span:first-of-type {
          font-size: utils.rem(14);
          font-weight: 450;
          letter-spacing: utils.rem(1.6);
        }

        > span:last-of-type > svg {
          width: 14px;
          height: 14px;
        }
      }

      &--selected > span {
        font-weight: 500;
        font-size: 400px;
      }
    }

    &-values {
      display: none;
      margin-top: utils.bu(1);
      font-weight: 400;

      :hover {
        background-color: map-get(colors.$background-color, bright-grey);
      }

      & > li {
        display: flex;
        align-self: center;
        padding: utils.bu(1.5) utils.bu(2.5);
      }

      &--active {
        display: block;
      }
    }
  }

  &__value--selected {
    color: map-get(colors.$primary-color, variant);
  }
}
