@use "../../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../../stylesheet/00_settings/color" as colors;

.scheduling-allocation {
  display: flex;
  justify-content: space-between;
  padding: utils.bu(1.5) utils.bu(2);
  background-color: map-get(colors.$secondary-color, variant);
  text-align: left;
  margin: 0 utils.rem(10);
  z-index: 1;
  position: relative;
  width: 189px;

  &__tooltip {
    padding: utils.bu(4) utils.bu(2);
    width: 170px;

    > span {
      font-size: utils.rem(10);
      letter-spacing: utils.rem(1);
      overflow-wrap: break-word;
    }
  }

  &--leave {
    margin: 0;
    width: 209px;
  }

  > span {
    font-size: utils.rem(12);
    font-weight: 400;
    letter-spacing: utils.rem(1.2);

    &:first-of-type {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: utils.bu(3);
    }

    &:last-of-type {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: utils.bu(.5);
      height: 100%;
    }
  }

  &--under-one-hour {
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;

    > span:last-of-type {
      margin-bottom: 0;
      height: unset;
    }
  }
}
