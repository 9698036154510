@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.card {
  padding: utils.bu(5) utils.bu(5) utils.bu(8) utils.bu(5);
  background-color: map-get(colors.$background-color, grey);
  border: utils.rem(1) solid map-get(colors.$border-color, primary);
  cursor: pointer;
  @include mixins.transition(background-color, .2s, ease-in);

  &--light {
    background-color: map-get(colors.$background-color, light-grey);
  }

  &--white {
    background-color: map-get(colors.$background-color, white);
  }

  &--grey {
    background-color: map-get(colors.$background-color, light);

    &:hover {
      background-color: map-get(colors.$primary-color, white);
    }
  }

  &--borderless {
    border: none;
  }

  &--shadowed {
    border: none;
    box-shadow: 0 utils.rem(1) utils.rem(4) map-get(colors.$box-shadow-color, primary);
  }

  &--dark {
    background-color: map-get(colors.$primary-color, black);
    color: map-get(colors.$primary-color, white);
  }

  &--transparent {
    background-color: transparent;
  }
}
