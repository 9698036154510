@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;

.dropdown {

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1; // place over page content
    background-color: transparent;
  }

  &__popper {
    opacity: 0;
    pointer-events: none;
    @include mixins.transition(opacity);

    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 2; // place over overlay
    }
  }
}

.dropdown-menu {
  min-width: utils.rem(165);
  border: utils.rem(1) solid map-get(colors.$border-color, primary);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    min-width: utils.rem(266);
  }

  &__item {
    font-size: utils.rem(12);
    border-bottom: utils.rem(1) solid map-get(colors.$border-color, primary);
    background-color: map-get(colors.$background-color, light);

    & > button {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: utils.bu(3) 0 utils.bu(3) utils.bu(5);
      @include mixins.transition(all, .2s, ease-in);

      &.icon {
        padding: utils.bu(3) 0 utils.bu(3) utils.bu(2);

        & > svg {
          margin-right: utils.bu(1.5);
        }
      }

      & > span {
        width: 100%;
        margin-right: utils.bu(1.5);
        font-size: utils.rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: utils.rem(1.2);
      }

      &:hover {
        background-color: map-get(colors.$primary-color, white);
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
