@use "../../../stylesheet/00_settings/color" as color;
@use "../../../stylesheet/00_settings/util" as util;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixin;

$table-row-header-height: util.rem(55);
$table-row-height: util.rem(59);
$table-font-size: util.rem(12);
$table-header-actions-width: util.rem(115);

.table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: none;
  background-color: transparent;
  position: relative;

  &__loader {
    height: 50vh;
    display: flex;
  }

  &__header {
    position: sticky;
    top: 0;
    background-color: map-get(color.$primary-color, white);
    z-index: 1;

    tr {
      height: $table-row-header-height;
      color: map-get(color.$primary-color, grey);
      border-bottom: 1px dashed map-get(color.$border-color, table);
    }
  }

  tr.table-row {
    transform: scale(1);
  }

  &-row--empty {
    padding-top: util.rem(60);
    padding-bottom: util.rem(60);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    border: util.rem(.5) solid map-get(color.$border-color, alternate);
    border-top: none;

    > h3 {
      font-size: util.rem(39);
      margin-bottom: util.bu(4);
      letter-spacing: util.rem(1.6);
    }

    > p {
      margin-bottom: 0;
      width: util.rem(337);
      font-size: util.bu(2);
      letter-spacing: util.rem(1.6);
    }
  }

  &__body {
    overflow-y: scroll;

    &-action {

      &--danger:hover {
        color: map-get(color.$primary-color, error);
      }
    }

    tr {
      height: $table-row-height;
      border-bottom: util.rem(1) dashed map-get(color.$border-color, table);

      &:hover {
        background-color: map-get(color.$background-color, table-hover);
      }

      td {
        border: none;
      }

      &.table-row {
        background-color: transparent;
        height: $table-row-height;
        transform: none;

        &:hover {
          cursor: pointer;
          background-color: map-get(color.$background-color, table-hover);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__title {
    color: map-get(color.$primary-color, eerie-black);
    margin-bottom: util.bu(2);
    width: 100%;
  }

  &__pagination {
    padding-top: util.rem(28);
    margin-left: auto;
  }
}

th.table-heading {
  font-size: util.rem(13);
  font-weight: 450;
  text-align: left;
  letter-spacing: util.rem(1.6);
  border: none;

  .table-heading__sort-icon {
    display: inline-block;
    margin-left: util.bu(1);

    > svg {
      @include mixin.opacity-hover;
    }
  }

  &:first-child {
    padding-left: util.bu(6);
  }

  &--centered {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--hugged {
    width: fit-content;
  }
}

td.table-cell {
  color: map-get(color.$primary-color, eerie-black);
  font-size: $table-font-size;
  letter-spacing: util.rem(1.6);
  text-align: left;

  &:first-child {
    padding-left: util.bu(6);
  }

  &:last-child {
    padding-right: util.bu(3);
  }

  &--centered {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--strong {
    font-weight: 500;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--hugged {
    width: fit-content;
  }

  &--actions {

    > svg {
      margin: auto;
    }
  }

  .input__input-wrapper {
    margin-top: 0;
  }

  &--hover {

    &:hover {
      font-weight: bold;
    }
  }
}
