@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/01_tools/mixin" as mixins;
@use "../../stylesheet/00_settings/extra" as extra;
@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/config" as config;

.employee-directory {
  top: utils.rem(20);
  position: relative;
  padding-left: utils.rem(0);
  width: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    padding-left: utils.bu(15);
    top: utils.rem(135);
  }

  &-mobile-cards {
    padding-bottom: utils.rem(20);

    & > .mobile-card {

      & > img {
        width: utils.rem(53);
        height: utils.rem(53);
        object-fit: cover;
        border-radius: map-get(extra.$border-radius, circular);
        margin-left: utils.bu(4);
        margin-right: utils.rem(21);
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;

      & > span {
        font-size: utils.rem(12);
        line-height: 150%;
        letter-spacing: utils.rem(1.6);
        padding-bottom: utils.rem(5);

        &:first-child {
          font-size: utils.bu(2);
        }

        &:last-child {
          font-size: utils.rem(10);
          line-height: 120%;
          padding-bottom: utils.rem(0);
        }
      }

      .input input {
        padding: 0;
      }
    }
  }

  .filter-layout__actions {
    padding-left: utils.rem(30);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      padding-left: utils.rem(0);
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > a {
      margin-right: utils.rem(79);
      margin-bottom: utils.bu(8);
    }
  }

  &__image {
    position: relative;
    width: utils.bu(15);
    height: utils.bu(15);
    border-radius: map-get(extra.$border-radius, circular);

    & > img {
      width: utils.bu(15);
      height: utils.bu(15);
      border-radius: map-get(extra.$border-radius, circular);
      object-fit: cover;
    }
  }

  &__employee-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding-top: utils.rem(20);

    h2 {
      font-size: utils.rem(25);
      font-weight: 500;
      color: #000;
      padding-bottom: utils.bu(1);
      padding-top: utils.rem(22);
    }

    span {
      font-size: utils.rem(12);
      font-weight: 400;
      padding-bottom: utils.bu(1);
    }

    p {
      font-size: utils.rem(10);
      font-weight: 400;
    }
  }

  &__bio {
    margin-top: utils.rem(127);
    margin-bottom: utils.rem(85);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    & > p {
      font-size: utils.bu(2);
      line-height: 200%;
      letter-spacing: utils.rem(1.6);
    }
  }

  .card {
    height: utils.rem(350);
    width: utils.rem(350);
  }
}

.flip-card {
  height: utils.rem(350);
  width: utils.rem(350);

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 800ms;
    transform-style: preserve-3d;
  }

  &__front,
  &__back {
    position: absolute;
    height: utils.rem(350);
    width: utils.rem(350);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &__back {
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
  }

  &:hover .flip-card__inner {
    transform: rotateY(180deg);
  }
}
