@use "../../../../stylesheet/00_settings/util" as utils;

.leave-category-form {

  > section > dialog {
    padding: utils.bu(4.5) utils.bu(4.5) utils.bu(7);

    > header {
      margin-bottom: utils.bu(5);

      > h3 {
        margin-left: 0;
        font-size: utils.rem(24);
        line-height: utils.rem(27.07);
      }
    }

    > section > form {
      padding-right: utils.bu(1.5);

      > .form-row > div {
        width: 100%;
      }

      > div:first-of-type {
        margin-bottom: utils.bu(4);
      }
    }
  }

  > &__actions {
    display: flex;
    justify-content: space-between;

    > div {
      width: unset;
    }
  }
}
