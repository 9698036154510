@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;

.client-list {
  top: 135px;
  position: relative;
  margin: 0 utils.bu(15);
  display: flex;
  flex-direction: column;

  &__table {
    background-color: map-get(colors.$background-color, light-grey);
    max-height: 619px;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
  }
}
