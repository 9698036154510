@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/06_components/components" as component;
@use "../../../../stylesheet/00_settings/extra" as extra;
@use "../../../../stylesheet/00_settings/color" as colors;

.timesheet-list {
  // utils.bu(7) is the space between the side menu and the component itself, as per the design
  margin-left: calc(#{component.$scheduling-settings-menu} + #{utils.bu(7)});
  top: 135px;
  position: relative;
  margin-right: utils.bu(15);

  &__table {
    background-color: map-get(colors.$background-color, light-grey);

    > table > tbody > tr > td > img {
      width: 28px;
      height: 28px;
      object-fit: cover;
      border-radius: map-get(extra.$border-radius, circular);
    }
  }

  &__modal {

    > section > dialog {
      padding-bottom: utils.bu(5);
      padding-right: utils.bu(4.5);
      padding-left: utils.bu(4.5);
      overflow: unset;
      width: 552px;
      height: 299px;

      > header {
        margin-bottom: utils.bu(2.5);

        > h3 {
          margin-top: 0;
          margin-left: 0;
          font-size: utils.rem(24);
          font-weight: 400;
        }
      }

      > section {

        > .button-group {
          justify-content: unset;
          gap: utils.bu(2);
        }

        > span {
          font-size: utils.rem(12);
          font-weight: 400;
          letter-spacing: utils.rem(.1);
        }
      }
    }
  }

  &__date-range {
    display: flex;
    gap: utils.bu(2);
    margin-bottom: utils.bu(5);
    margin-top: utils.bu(4);

    .datepicker {
      width: 229px;
    }
  }

  &__client {
    margin-bottom: utils.bu(5);
    margin-top: utils.bu(4);
  }
}
