@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/06_components/components" as component;

.scheduling-access-settings {
  // utils.bu(7) is the space between the side menu and the component itself, as per the design
  margin-left: calc(#{component.$scheduling-settings-menu} + #{utils.bu(7)});
  top: utils.rem(135);
  position: relative;
  margin-right: utils.bu(15);

  > .filter-layout {
    display: flex;
    flex-direction: column;

    > button {
      align-self: flex-end;
      margin-top: utils.rem(49);
      margin-bottom: utils.rem(29);
    }
  }

  &__loader {
    display: flex;
    height: 100vh;
  }

  &--setup {
    margin-left: utils.bu(15);
  }
}
