@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.dialog-layout {
  width: calc(100% - #{utils.rem(50)});
  min-height: utils.rem(250);
  overflow-y: scroll;
  padding: utils.bu(7) utils.bu(3);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: white;
  border: none;
  $block: &;

  @include mixins.breakpoint(map-get(config.$breakpoints, small)) {
    width: calc(100% - #{utils.bu(25)});
    padding: utils.bu(7) utils.bu(9);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(600);
    padding: utils.bu(3);
  }

  &__header {
    display: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: utils.bu(2);
    }
  }

  &__close {
    @include mixins.opacity-hover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 0 utils.bu(9);
    }
  }

  &__heading {
    display: flex;
    text-align: center;
    line-height: 120%;
    font-size: utils.bu(3);
    margin-bottom: utils.bu(3);
    min-width: utils.bu(28);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(36);
      margin-bottom: utils.bu(6);
    }
  }

  &__message {
    text-align: center;
    font-size: utils.rem(16);
    font-weight: 400;
    line-height: utils.rem(32);
    letter-spacing: utils.rem(1.6);
    margin-bottom: utils.rem(66);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(12);
      margin-bottom: utils.bu(6);
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    & > .button:not(:first-child) {
      margin-top: utils.bu(3);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-top: 0;
        margin-left: utils.bu(3);
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      flex-direction: row;
      margin-bottom: utils.bu(4);
    }
  }
}
