@use "../../../stylesheet/00_settings/util" as util;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

#kudos-sidebar {
  display: flex;
  flex-direction: column;
  padding-top: util.rem(28);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    // 200px is the starting position of the this component from the top.
    height: calc(100vh - #{util.bu(25)});
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    max-width: util.rem(350);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
