@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/util" as utils;

.checkbox {
  position: relative;
  width: utils.rem(24);
  height: utils.rem(24);
  cursor: pointer;
  border: utils.rem(1) solid map-get(colors.$primary-color, black);
  @include mixins.transition(all, .1s);

  &:checked::before,
  &--indeterminate::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: utils.rem(7);
    height: utils.rem(17);
    border: solid map-get(colors.$primary-color, black);
    border-width: 0 utils.rem(1) utils.rem(1) 0;
  }

  &--indeterminate::before {
    transform: translate(-50%, -50%) rotate(90deg);
    width: 1px;
    height: 18px;
    border-width: 0 utils.rem(1) 0 0;
  }

  &:hover {
    background-color: map-get(colors.$primary-color, light);
  }
}
