@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.input__action {
  cursor: pointer;
  color: map-get(colors.$primary-color, primary);
  font-weight: 350;
  padding-right: utils.rem(12);

  &--disabled {
    color: map-get(colors.$secondary-color, disabled);
    cursor: default;
  }

  &--error {
    color: map-get(colors.$border-color, error);
  }

  > span {
    @include mixins.opacity-hover;
  }
}
