@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;

.category-card {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: utils.rem(30) utils.bu(3);
  opacity: 1;

  &--no-padding {
    padding: 0;
  }

  &--inactive {
    opacity: .4;
    pointer-events: none;
  }

  & > form > div:nth-of-type(2) > div > fieldset {
    height: 84px;
    padding-right: utils.bu(5);
    padding-left: utils.bu(2);

    > textarea {
      height: 54px;
      padding: 0;
      white-space: normal;
      overflow: scroll;
      font-size: utils.rem(12);
      font-weight: 400;
      line-height: utils.rem(18);
      letter-spacing: utils.rem(1.2);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  & .form > .form-row + .form-row {
    margin-top: utils.bu(2);
  }

  & .form > .form-row + .form-row:last-of-type {

    & > .number-picker {
      margin-left: utils.bu(5);
    }
  }

  & .form-row {
    margin-top: utils.bu(2);
    display: flex;

    & > div + div {
      margin-top: 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: utils.bu(2);

    &--button-large {
      flex-direction: column;
      align-items: center;
    }

    & > button {
      width: utils.rem(215);
    }

    & > button:first-child {
      margin-bottom: utils.rem(12);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      flex-direction: row;
      align-items: start;

      & > button:first-child {
        margin-right: utils.rem(37);
        margin-bottom: 0;
      }
    }
  }
}
