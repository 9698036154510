@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/06_components/components" as component;

.nav-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: utils.rem(260);
  height: 100vh;
  background-color: map-get(colors.$background-color, light);
  z-index: map-get(component.$z-indexes, nav-menu);
  transform: translateX(-100%);
  @include mixins.transition(transform);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(347);
  }

  &__close-action {
    display: flex;
    justify-content: flex-end;
    padding: utils.bu(3) utils.bu(3) utils.bu(4) 0;
    border-bottom: utils.rem(1) solid map-get(colors.$border-color, primary);

    > button:hover {
      font-weight: 450;
    }
  }

  &--open {
    transform: translateX(0%);
  }

  & > ul > li {
    border-bottom: utils.rem(1) solid map-get(colors.$border-color, primary);
    font-size: utils.rem(20);
    cursor: pointer;
    @include mixins.transition(background-color, .2s);

    &:hover {
      background-color: map-get(colors.$primary-color, white);
    }

    > a {
      display: block;
      padding: utils.rem(28) 0 utils.rem(28) utils.rem(52);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100%);
    @include mixins.transition(transform, .1);

    &--open {
      opacity: .8;
      z-index: map-get(component.$z-indexes, nav-menu-overlay); // place over overlay
      background-color: map-get(colors.$background-color, dark);
      transform: translateX(0%);
    }
  }
}
