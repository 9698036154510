@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.points-card {
  width: 100%;
  font-weight: 400;
  letter-spacing: .1em;
  text-align: left;
  margin-bottom: utils.rem(22);

  & > .card {
    position: relative;
    padding: utils.rem(20);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: utils.rem(20);
  }

  &__arrow {
    transform: rotate(-90deg);
    @include mixins.opacity-hover;
  }

  &__subtitle {
    font-size: utils.rem(14);
    padding-top: utils.rem(14);
    margin-top: utils.rem(14);
    display: block;
  }

  &__stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: utils.rem(20);
    font-weight: 400;
    letter-spacing: .1em;
    text-align: center;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    margin-top: utils.bu(2);
    font-size: utils.rem(12);
    font-weight: 400;
    letter-spacing: .1em;

    &-empty-message {
      margin-top: utils.bu(5);
      margin-bottom: utils.rem(37);
      font-size: utils.rem(10);
      line-height: 120%;
    }
  }
}
