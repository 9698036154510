@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/00_settings/color" as colors;

/* The loader is positioned here to cover the viewport while loading,
    ensuring visibility because the loader is not part of the form. */

#client-edit-form-loader {
  display: flex;
  height: 100vh;
}

.client-edit-form {
  position: relative;
  margin-left: utils.bu(15);
  margin-right: utils.bu(15);
  top: 135px;

  > form > fieldset {
    background-color: map-get(colors.$primary-color, white);
    padding: utils.bu(8) utils.bu(9) utils.bu(7);

    > div:first-of-type > div:last-of-type > fieldset > input {
      font-size: utils.rem(25);
    }

    .form-row:not(:last-of-type) {
      margin-bottom: utils.bu(4);
    }

    > div:first-of-type > div {
      margin-right: utils.bu(1);
    }

    > div:nth-of-type(2) > ul {
      display: flex;
      width: 100%;
      column-gap: 32px;
      row-gap: 4px;
      flex-wrap: wrap;

      > li {
        border: 1px solid map-get(colors.$border-color, tertiary);
        width: calc(50% - 16px);
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: utils.bu(2.5);
        padding-left: utils.bu(2);
      }
    }
  }

  > form > fieldset > .form-row > div:last-of-type {
    width: 100%;
  }

  > &__actions {
    display: flex;
    justify-content: space-between;

    > div {
      width: unset;
    }
  }
}
