@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.points-stat {
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    margin-left: utils.bu(1);
  }

  &__value {
    font-size: utils.rem(20);
    font-weight: 450;
    margin-bottom: utils.rem(12);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(25);
      font-weight: 400;
    }
  }

  &__description {
    font-size: utils.rem(12);
    height: 100%;
  }
}
