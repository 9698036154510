@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/00_settings/color" as colours;

.task-allocation-form {

  > .form > .task-allocation-form__time-content > .form-row {

    &:first-of-type {

      .search-options {
        margin-left: 0;
        padding-top: utils.bu(.5);
      }
    }

    &:last-of-type {
      margin-bottom: utils.bu(3);
    }
  }

  > form > div > .form-row {

    > .search-options-with-add {

      > .dropdown__trigger {
        width: 100%;
      }

      > .dropdown__popper > .dropdown-menu {
        max-height: 208px;
      }
    }
  }

  &__time-wrapper {
    background-color: rgba(map-get(colours.$background-color, contact-action), .4);
    padding: utils.bu(3) utils.bu(2) utils.bu(2);
    margin-bottom: utils.bu(4.5);
    height: 150px;

    .input__wrapper {
      max-height: 48px;

      &:first-of-type > input {
        font-size: utils.rem(16);
        text-align: center;
        letter-spacing: utils.rem(1.6);
        padding-left: 0;
      }
    }
  }

  &__time-inputs {
    max-width: 81px;

    > div {
      margin-bottom: utils.bu(1);
    }

    > span {
      font-size: utils.rem(14);
    }
  }

  .datepicker {

    .input__wrapper {

      input {
        width: 177px;
      }
    }
  }

  &__time-content {
    overflow-y: scroll;
    width: 624px;
    height: 286px;
    gap: utils.bu(4);
    display: flex;
    flex-direction: column;

    > div {
      max-width: 602px;
    }
  }

  &__time-actions {
    display: none; // Change to display: flex when implementing the Doesn’t Repeat functionality
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: utils.bu(2);
    margin-top: utils.bu(1.5);

    > div:last-of-type {
      display: flex;
    }
  }

  &__actions {
    position: sticky;
    width: 602px;
    display: flex;
    align-items: center;
    margin-left: utils.bu(3);

    > button {
      margin-top: utils.bu(4);
      margin-left: utils.bu(11);
    }
  }
}
