@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/06_components/components" as component;

.breadcrumb {
  position: fixed;
  z-index: 2;
  padding: utils.bu(5) 0 utils.bu(5) utils.rem(35);
  background-color: map-get(colors.$background-color, light);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    padding: utils.bu(8) 0 utils.bu(6) utils.rem(50);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    padding: utils.bu(8) 0 utils.bu(6) utils.rem(121);
  }

  > ul {
    display: flex;

    > li {

      > a {
        cursor: pointer;

        &:hover {
          color: map-get(colors.$secondary-color, hover);
        }

        &:not(:last-of-type)::after {
          content: "/";
          margin-left: utils.rem(3);
          margin-right: utils.rem(3);
        }
      }

      > span {
        // some elements in the breadcrumb can be non-navigate-able
        letter-spacing: .1rem;
      }

      &::after {
        content: "/";
        margin-left: utils.rem(3);
        margin-right: utils.rem(3);
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  &--full-width {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--scheduling-settings {
    // 56px is the space between the side menu and the component itself, as per the design
    left: calc(#{component.$scheduling-menu} + #{component.$scheduling-settings-menu} + 56px);
    padding-left: 0;
  }

  &--timesheet-page {
    left: 40px;
  }
}
