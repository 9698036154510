@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.text-area {
  position: relative;
  width: 100%;
  $block: &;

  &__label {
    @include mixins.input-label;
  }

  &__icon {
    cursor: pointer;
    color: map-get(colors.$primary-color, primary);
    font-weight: 350;
    padding-right: utils.rem(12);
  }

  &__input-wrapper {
    @include mixins.textarea-container;
  }

  &__error {
    display: block;
    font-size: utils.rem(12);
    color: map-get(colors.$primary-color, error);
    margin-top: utils.bu(1);
    padding-left: utils.rem(19);
  }

  textarea {
    @include mixins.textarea-content;
  }

  &--filled {
    #{$block}__input-wrapper {
      border: utils.rem(1) solid rgba(map-get(colors.$border-color, secondary), .8);
    }

    textarea,
    #{$block}__label {
      color: rgba(map-get(colors.$primary-color, primary), .8);
    }

    #{$block}__label {
      visibility: visible;
      @include mixins.transition(ease-in);
    }
  }

  &--active {
    #{$block}__input-wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
    }

    textarea,
    #{$block}__label {
      color: map-get(colors.$primary-color, primary);
    }

    #{$block}__label {
      visibility: visible;
      @include mixins.transition(ease-in);
    }
  }

  &--error {
    #{$block}__input-wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, error);
    }

    textarea,
    textarea:focus,
    #{$block}__icon,
    #{$block}__label {
      color: map-get(colors.$border-color, error);
    }
  }

  &--disabled {
    #{$block}__icon {
      color: map-get(colors.$secondary-color, disabled);
      cursor: default;
    }

    #{$block}__input-wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, primary);
    }

    #{$block}__label {
      color: map-get(colors.$secondary-color, alternate);
    }

    textarea {
      color: map-get(colors.$secondary-color, disabled);
      @include mixins.placeholder(map-get(colors.$secondary-color, disabled));
    }
  }
}
