@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extra;

.search-options-with-add {

  .dropdown-menu {
    max-height: utils.rem(316);
    overflow-y: scroll;
    padding: 0;
    background-color: map-get(colors.$background-color, light);
    border-color: map-get(colors.$border-color, primary);

    &__item {
      padding: 0;
      background-color: map-get(colors.$background-color, light);

      &:hover {
        background-color: map-get(colors.$background-color, grey);
      }

      & > button {
        width: 100%;
        padding: utils.rem(18) 0 utils.rem(18) utils.rem(14);
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    letter-spacing: utils.rem(1.6);
    flex-direction: row-reverse;

    & > span {
      flex: 1;
    }

    &__option-ellipsis {
      margin: 0;
    }

    &-ellipsis {
      margin: 0;
      width: utils.bu(2);
      height: utils.bu(2);
      border: utils.rem(1) solid map-get(colors.$border-color, secondary);
      border-radius: map-get(extra.$border-radius, circular);
      cursor: pointer;

      &--checked::after {
        top: utils.rem(1);
        width: utils.rem(12);
        height: utils.rem(12);
        margin: auto;
        content: '';
        display: block;
        background-color: map-get(colors.$background-color, dark);
        position: relative;
        visibility: visible;
        border-radius: map-get(extra.$border-radius, circular);
        border: none;
      }
    }
  }

  &--open {

    .input__icon {
      padding-right: 0;
      padding-left: utils.rem(12);
      transform: rotate(180deg);
    }
  }

  &__no-results {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: utils.rem(14);
    font-weight: 400;

    > svg {
      width: utils.rem(18);
      height: utils.rem(18);
      margin-right: utils.rem(11);
    }
  }
}
