@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;

.category-tooltip-item {
  width: 100%;
  font-size: utils.rem(12);
  letter-spacing: utils.rem(1.2);
  overflow-wrap: anywhere;

  &__header {
    display: flex;

    & > span:first-child {
      flex: 1;
      font-weight: 450;
    }
  }

  & > hr {
    border: none;
    border-top: solid utils.rem(1) map-get(colors.$secondary-color, variant);
    margin: utils.bu(1) 0;
  }

  & span:last-child {
    font-weight: 300;
  }
}
