@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/06_components/components" as component;

.snack-bar {
  position: fixed;
  top: utils.rem(35);
  right: utils.bu(3);
  z-index: map-get(component.$z-indexes, snack-bar);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    top: utils.bu(6);
    right: utils.bu(4);
  }
}
