@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/util" as utils;

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > header {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    top: 0;
    background-color: map-get(colors.$background-color, light);
    z-index: 10;

    & > article {
      display: flex;
      justify-content: center;
      padding: utils.bu(3) 0;
      width: utils.rem(727);
      border-bottom: utils.rem(1) solid map-get(colors.$border-color, secondary);
    }
  }

  & > h1 {
    margin-top: utils.rem(79);
    font-size: utils.bu(45);
    font-weight: 500;
    letter-spacing: utils.rem(.112);
  }

  & > p {
    margin-top: utils.bu(-2);
  }

  & > .button {
    margin-top: utils.rem(20);
  }
}
