@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/01_tools/mixin" as mixins;

.stepper {
  display: flex;
  gap: utils.bu(7);

  &__menu {
    height: fit-content;
    width: 273px;
    border: 1px solid map-get(colors.$border-color, primary);

    &-item {
      border-bottom: 1px solid map-get(colors.$border-color, primary);
      font-size: utils.rem(12);
      letter-spacing: utils.rem(1.6);
      color: map-get(colors.$secondary-color, secondary);
      font-weight: 400;
      cursor: pointer;
      @include mixins.transition(background-color);

      > button {
        cursor: pointer;
        padding: utils.bu(2) 0 utils.bu(2) utils.bu(3);
        height: 100%;
        width: 100%;
        text-align: start;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: map-get(colors.$background-color, white);
      }

      &--selected {
        background-color: map-get(colors.$background-color, white);
        font-weight: 450;
      }

      &--disabled {
        pointer-events: none;
        opacity: .4;
      }
    }
  }

  > section {
    width: 100%;
  }
}
