@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/extra" as extras;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.snack {
  display: flex;
  width: utils.rem(330);
  min-height: utils.rem(71);
  padding: utils.bu(2) utils.rem(30);
  background-color: map-get(colors.$primary-color, white);
  box-shadow: rgba(map-get(colors.$primary-color, black), .08);
  align-items: center;
  justify-content: space-between;
  color: 0 utils.rem(2) utils.rem(6) utils.rem(1) map-get(colors.$primary-color, black);
  $block: &;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(460);
    min-height: utils.bu(7);
  }

  &__title {
    max-width: utils.rem(366);
    font-size: utils.rem(12);
    letter-spacing: utils.rem(1.6);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: utils.rem(13);
  }

  & + & {
    margin-top: utils.bu(1);
  }
}
