@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.complete-profile-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > header {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    top: 0;
    background-color: map-get(colors.$background-color, light);
    z-index: 10;

    & > article {
      display: flex;
      justify-content: center;
      padding: utils.bu(3) 0;
      width: utils.rem(310);
      border-bottom: utils.rem(1) solid map-get(colors.$border-color, secondary);

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        width: utils.rem(892);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
        width: utils.rem(727);
      }

      & > svg {
        width: utils.rem(126);
        height: utils.rem(26);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          width: utils.rem(130);
          height: utils.rem(30);
        }
      }
    }
  }
}
