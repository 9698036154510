@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/extra" as extras;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.delete-nomination {
  margin-top: utils.bu(5);
  padding-left: utils.bu(2);
  padding-right: utils.bu(2);
  padding-bottom: utils.bu(4.5);

  > header {

    > h1 {
      font-size: utils.rem(20);
      margin-bottom: utils.bu(3);
    }
  }

  & .select {

    & .input__label {
      background-color: map-get(colors.$background-color, light);
    }

    & .dropdown-menu {
      background-color: map-get(colors.$background-color, light);

      &__item {
        background-color: map-get(colors.$background-color, light);
      }
    }
  }

  &__label {
    display: flex;
    font-size: utils.rem(12);
    margin-bottom: utils.rem(20);
    margin-top: utils.rem(20);
    color: map-get(colors.$secondary-color, secondary);
    letter-spacing: utils.rem(1.2);
  }
}

.delete-nomination-modal {

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    margin: 0;
  }

  & .modal-layout {
    background-color: map-get(colors.$background-color, light);
    width: utils.rem(838);

    &__header {
      margin-bottom: 0;
      text-align: left;
      flex-direction: column-reverse;

      & > span {
        align-self: flex-end;
      }

      & > h3 {
        margin-left: utils.bu(2);
      }
    }
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    background-color: map-get(colors.$background-color, light);
  }
}
