@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.profile-details-section {
  position: relative;
  top: utils.rem(79);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    padding: utils.bu(7) 0 utils.rem(45) utils.rem(50);
    flex-direction: row;
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    padding: utils.bu(7) 0 utils.rem(45) utils.rem(121);
    flex-direction: row;
  }

  & > aside {

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      position: fixed;
    }
  }

  &__details {
    position: relative;
    width: 100%;
    height: 130%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(590);
      left: utils.rem(273);
      margin-left: utils.rem(30);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
      width: utils.rem(746);
      margin-left: utils.rem(54);
    }

    & > div > .card {
      padding: utils.bu(4) utils.rem(30);
      margin-bottom: 0;
      cursor: default;

      @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
        padding: utils.bu(7) utils.bu(9);
      }

      & > .form > .form-row {

        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          display: block;
        }

        @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
          display: flex;
        }

        & > .select {

          .radio-selector {
            flex-direction: row-reverse;
            justify-content: flex-end;

            > label {
              margin-right: utils.bu(2);
            }
          }

          > button {
            width: 100%;
          }
        }

        & > div + div {
          margin-top: utils.bu(3);
          margin-left: 0;

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            margin-top: 0;
            margin-left: utils.bu(7);
          }

          @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
            margin-top: utils.bu(3);
            margin-left: 0;
          }

          @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
            margin-top: 0;
            margin-left: utils.bu(7);
          }
        }
      }
    }

    & > p {
      margin: utils.bu(3) utils.rem(33) 0;
      color: map-get(colors.$primary-color, primary);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin: utils.rem(36) unset 0;
      }
    }

    &--image {

      & > .card {
        margin-left: utils.rem(33);
        margin-right: utils.rem(33);
        margin-bottom: 0;

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-left: unset;
          margin-right: unset;
        }
      }
    }
  }

  &__actions {
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: utils.bu(4) utils.rem(58);
    display: flex;
    justify-content: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      position: relative;
      bottom: unset;
      margin-top: utils.bu(5);
      padding: 0;
      justify-content: unset;
      background: unset;
      backdrop-filter: unset;
    }

    & > .button {
      position: relative;
      z-index: 2;
      width: utils.rem(260);
      height: utils.rem(60);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        width: utils.rem(204);
        height: utils.bu(6);
      }
    }

    & > .button + .button {
      margin-left: utils.bu(3);
    }
  }

  &--edit-mode {
    top: 0;
    padding-top: 0;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      top: utils.rem(130);
    }
  }
}
