@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;

.selected-colour-picker {

  &__picker {
    position: relative;

    > button {
      cursor: pointer;
      display: flex;
      position: absolute;
      bottom: 5px;
      right: -5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: map-get(colors.$primary-color, white);

      > svg {
        width: 12px;
        height: 12px;
        margin-left: utils.bu(.25);
        align-self: center;
        justify-self: center;
      }

      > input {
        width: 0;
        position: relative;
        visibility: hidden;
      }
    }
  }
}
