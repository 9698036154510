@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/06_components/components" as component;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.nav-bar {
  position: fixed;
  top: 0;
  z-index: map-get(component.$z-indexes, nav-bar);
  width: 100%;
  height: utils.rem(component.$header-height);
  display: flex;
  align-items: center;
  background-color: map-get(colors.$background-color, light);
  border-bottom: utils.rem(1) solid map-get(colors.$border-color, primary);

  &__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 55vw;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: 50vw;
    }

    &:nth-child(2) {
      justify-content: flex-end;
      width: 45vw;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        width: 50vw;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__menu-button {
    width: utils.rem(146);
    height: 100%;
    border-right: utils.rem(1) solid map-get(colors.$border-color, primary);

    & > button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: utils.bu(2);
      letter-spacing: utils.rem(1.6);

      & > svg {
        margin-left: utils.rem(12);
      }
    }
  }

  &__menu-button-wrapper {
    display: flex;
    justify-content: center;
    width: utils.bu(10);
    height: utils.bu(9);
    border-right: utils.rem(1) solid map-get(colors.$border-color, primary);
  }

  &__search {
    margin-left: utils.rem(36);
  }

  &__logo {
    cursor: pointer;
    margin-left: auto;
    position: relative;
  }

  &__notification {
    margin-right: utils.rem(18);
    cursor: pointer;
    padding: utils.bu(.5);
    @include mixins.opacity-hover;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-right: utils.bu(4);
    }
  }

  &__profile {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: utils.bu(1);
    margin-right: utils.bu(4);

    & > small {
      font-size: utils.rem(12);
      margin-right: utils.rem(14);
      @include mixins.opacity-hover;
    }

    & > img {
      width: utils.bu(4);
      height: utils.bu(4);
      border-radius: map-get(extra.$border-radius, circular);
      object-fit: cover;
    }
  }

  &__context {
    height: 100%;

    & > .button {
      height: 100%;
      text-transform: uppercase;
    }
  }

  & > .dropdown__trigger {
    height: 100%;
  }
}
