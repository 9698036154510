@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/06_components/components" as component;

.scheduling-settings-menu {
  width: 200px;
  padding-top: utils.bu(4);
  display: flex;
  position: fixed;
  height: calc(100% - #{utils.rem(component.$header-height)});
  border-left: 1px solid map-get(colors.$secondary-color, variant);
  border-right: 1px solid map-get(colors.$secondary-color, variant);
  z-index: 2;

  > div {

    > header {
      display: flex;
      flex-direction: column;
      padding-left: utils.bu(3);
      margin-bottom: utils.bu(3);
      height: 40px;

      > h3:first-of-type {
        font-size: utils.rem(20);
        margin-bottom: utils.bu(1.5);
      }

      > span:last-of-type {
        font-size: utils.rem(10);
        font-weight: 350;
        line-height: utils.rem(18);
      }
    }

    > nav {

      &:first-of-type > a > div > svg:first-of-type {
        width: 14px;
        height: 14px;
      }

      > .nav-menu-item--active {

        > div > svg {
          color: map-get(colors.$primary-color, variant);
        }
      }

      > a {
        display: flex;
        align-items: center;
        padding: utils.bu(2);

        &:hover {
          background-color: map-get(colors.$secondary-color, light);
        }

        > div {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: utils.bu(1.5);
        }

        > span {
          line-height: utils.rem(18);
          font-size: utils.rem(16);
        }
      }
    }
  }
}
