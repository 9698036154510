@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/color" as colours;

.scheduling-allocation-form {
  margin-right: utils.bu(9);
  margin-left: utils.bu(9);

  &__tabs {
    margin-bottom: utils.bu(6);
    margin-left: utils.bu(3);
    display: flex;
    align-items: flex-start;
    position: sticky;
    background-color: map-get(colours.$background-color, white);
    width: 604px;
  }

  .tabs {

    &__tab {
      padding-bottom: utils.bu(2);
    }
  }

  &__selected-tab {
    padding-bottom: utils.bu(1);
    border-bottom: 1px solid map-get(colours.$primary-color, black);
  }

  .modal-layout {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 746px;
    height: 680px;
    position: absolute;
  }

  .form {
    height: 473px;
    width: 650px;
    padding: 0 utils.bu(3);
  }

  .form-row > div {
    width: 100%;
  }

  .form-row:first-of-type {

    > div > div > fieldset > input {
      width: 81px;
    }

    > div:nth-of-type(2) {
      margin-left: utils.bu(7);
    }

    > div:last-of-type {
      margin-left: utils.bu(2);
    }
  }

  .modal-layout__header {
    margin-bottom: 0;
    margin-top: utils.bu(3.5);
    margin-right: utils.bu(3.5);
    position: absolute;
    top: 0;
    right: 0;
  }

  .modal-layout__content {
    justify-self: flex-start;
    margin: 0 utils.bu(9);
    height: 600px;
    position: fixed;

    .form-action {
      margin-top: utils.bu(2);

      .button--secondary {
        margin-left: auto;
      }
    }
  }
}
