@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/extra" as extra;
@use "../../../../stylesheet/00_settings/color" as colours;

.scheduling-calendar {
  display: flex;
  margin-left: utils.bu(10);
  margin-top: utils.bu(12.5);
  $block: &;

  &__members {
    width: 272px;
    min-width: 272px;
    background-color: map-get(colours.$background-color, white);

    > &--time {
      background-color: map-get(colours.$background-color, iron-oxide-red);
      color: map-get(colours.$primary-color, variant);
      height: 26px;
      width: 64px;
      padding: utils.bu(.5) utils.bu(2);
      margin-left: auto;
      cursor: default;
    }

    .dropdown-menu {
      min-width: 140px;
    }

    #{$block}__filter {
      display: flex;
      height: 18px;
      align-items: center;
      cursor: pointer;

      > span {
        font-weight: 350;
        font-size: utils.rem(16);
        line-height: utils.rem(17.86);
        margin-right: utils.bu(1.5);
      }
    }

    > header {
      height: 89px;
      display: flex;
      align-items: center;
      padding-left: utils.bu(2.5);
      padding-right: utils.bu(2.5);
      justify-content: space-between;
      position: fixed;
      width: 272px;
      border: 1px solid map-get(colours.$secondary-color, variant);
      background-color: map-get(colours.$primary-color, white);
    }

    > #{$block}__empty-state {
      height: 100vh;

      > .no-results {
        border: none;
        border-top: 1px solid map-get(colours.$secondary-color, variant);
        align-items: center;
        display: flex;

        > section {
          flex-direction: column;
          align-items: center;
          gap: utils.bu(1);
        }

        > h3 {
          font-weight: 400;
          font-size: utils.rem(24);
          line-height: utils.rem(27.07);
          letter-spacing: utils.rem(2.4);
          width: 240px;
          text-align: center;
        }

        > p {
          width: 240px;
        }
      }
    }

    > #{$block}__user-info {
      min-height: 216px;
      border-top: 1px solid map-get(colours.$secondary-color, variant);
      border-left: 1px solid map-get(colours.$secondary-color, variant);
      border-right: 1px solid map-get(colours.$secondary-color, variant);
      padding-top: utils.bu(10);
      padding-right: utils.bu(2);
      padding-left: utils.bu(2);
      display: flex;

      &:first-of-type {
        border-top: none;
        margin-top: 88px; // needs to match header height
      }

      &:last-of-type {
        border-bottom: 1px solid map-get(colours.$secondary-color, variant);
      }

      &--personal {
        height: 100vh;
      }

      > img {
        width: 44px;
        height: 47px;
        min-width: 44px;
        min-height: 47px;
        object-fit: cover;
        border-radius: map-get(extra.$border-radius, circular);
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: utils.bu(.5);
        margin-left: utils.bu(2);
        margin-right: utils.bu(2);

        > #{$block}__name {
          font-weight: 450;
          font-size: utils.rem(16);
          line-height: utils.rem(18);
        }

        > #{$block}__department {
          font-weight: 400;
          font-size: utils.rem(12);
          line-height: utils.rem(11);
          letter-spacing: utils.rem(1.2);
        }

        > #{$block}__job-title {
          font-weight: 400;
          font-size: utils.rem(10);
          line-height: utils.rem(14);
          letter-spacing: utils.rem(1);
        }
      }
    }
  }

  &__tooltip {
    width: 180px;
    height: 68px;
    padding: utils.bu(1.5) utils.bu(2.5);

    > div {

      > span:first-of-type {
        margin-right: utils.bu(.5);
        font-weight: 450;
        font-size: utils.rem(16);
      }

      > span:last-of-type {
        font-size: utils.rem(12);
        font-weight: 400;
        letter-spacing: utils.rem(1.2);
      }
    }

    > div:last-of-type > span:first-of-type {
      color: map-get(colours.$secondary-color, variant);
    }
  }
}
