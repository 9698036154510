@use "../../stylesheet/00_settings/color" as colors;
@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/00_settings/config" as config;
@use "../../stylesheet/00_settings/extra" as extra;
@use "../../stylesheet/01_tools/mixin" as mixins;

.notification {
  display: flex;
  margin-top: utils.rem(21);
  padding: utils.rem(10) utils.rem(30) utils.rem(10) utils.bu(2.5);
  width: 100%;

  &:first-of-type {
    margin-top: utils.bu(4);
  }

  & > img {
    height: utils.bu(5);
    width: utils.bu(5);
    margin-right: utils.rem(28);
    border-radius: map-get(extra.$border-radius, circular);
    object-fit: cover;
  }

  &--nomination:hover {
    background-color: map-get(colors.$secondary-color, light);
  }

  &--timesheet:hover {
    background-color: map-get(colors.$secondary-color, light);
    cursor: pointer;
  }

  &__message {
    display: flex;
    flex-direction: column;
    width: utils.bu(25);
    align-items: flex-start;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(306);
    }

    & > time {
      color: map-get(colors.$primary-color, light-grey);
    }

    & > p {
      text-align: left;
      line-height: 120%;
      letter-spacing: utils.rem(1.6);
      font-size: utils.bu(2);
      margin-bottom: utils.rem(18);

      & > span {
        font-weight: bold;
        letter-spacing: utils.rem(1.6);
      }
    }
  }

  & > .unread-indicator {
    margin-left: utils.rem(18);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-left: utils.bu(5);
    }
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    padding-left: utils.rem(41);
    padding-right: utils.bu(3.5);
  }
}
