@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/00_settings/extra" as extras;

$default-size: 14px;
$large-size: 30px;

.blip {
  display: flex;
  align-items: center;
  padding: utils.bu(1) 0;

  &__color {
    width: $default-size;
    height: $default-size;
    min-width: $default-size;
    border-radius: map-get(extras.$border-radius, circular);

    &--rectangular {
      width: 20px;
      height: 4px;
      min-width: 20px;
      border-radius: 0;
    }

    &--large {
      width: $large-size;
      height: $large-size;
      min-width: $large-size;
    }
  }

  &__label,
  &__secondary-label {
    margin-left: utils.bu(1.5);
  }
}
