@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extras;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/06_components/components" as component;

.modal {
  $modal-form: &;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: map-get(component.$z-indexes, modal);
  opacity: 1;
  width: 100%;
  height: 100%;

  &__overlay {
    z-index: map-get(component.$z-indexes, modal-overlay);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get(colors.$background-color, dark), .8);

    #{$modal-form}__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal-layout {
  width: calc(100% - #{utils.rem(50)});
  min-height: utils.rem(250);
  overflow-y: scroll;
  padding: utils.bu(3);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: map-get(colors.$background-color, light-grey);
  border: none;
  $block: &;

  @include mixins.breakpoint(map-get(config.$breakpoints, small)) {
    width: calc(100% - #{utils.bu(25)});
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(600);
  }

  &--large {
    width: calc(100% - #{utils.rem(50)});
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(700);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: utils.bu(3);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-bottom: utils.bu(7);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      text-align: left;
    }

    &-title {
      margin-top: utils.bu(7);
      margin-left: auto;
      margin-right: auto;
      font-size: utils.bu(3);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-top: utils.rem(18);
        font-size: utils.rem(36);
      }
    }
  }

  &__close {
    @include mixins.opacity-hover;
  }
}
