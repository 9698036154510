@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extras;
@use "../../../stylesheet/01_tools/mixin" as mixin;

.colour-select {

  > &__item-container {
    border-left: 1px solid map-get(colors.$secondary-color, variant);
    align-items: center;
    margin-right: utils.bu(1.5);
    padding-bottom: utils.bu(4.5);
    height: 35px;
    margin-top: utils.rem(6);
  }

  &__field-container {
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-left: utils.bu(1);

    > fieldset {
      padding-bottom: utils.bu(1);
    }
  }

  &__field-container::-webkit-scrollbar {
    display: none;
  }

  & > div > fieldset {
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    display: block;
    font-size: utils.rem(12);
    color: map-get(colors.$secondary-color, secondary);
    letter-spacing: utils.rem(1.2);
  }

  &__radio-group {
    width: utils.rem(30);
    height: utils.rem(30);
    border-radius: map-get(extras.$border-radius, circular);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: utils.bu(1);
    @include mixin.opacity-hover(.7);

    &:not(:last-of-type) {
      margin-right: utils.rem(12);
    }

    &--selected {
      position: relative;

      &:last-of-type {

        > svg {
          display: none;
        }

        &:hover {
          background-color: map-get(colors.$background-color, contact-action) !important;
          border-color: map-get(colors.$background-color, contact-action) !important;

          --hover-border-colour: #e5e6e8;

          > svg {
            display: inline;
          }
        }
      }
    }

    &--selected::before {
      content: '';
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: map-get(extras.$border-radius, circular);
      border: 1.5px solid var(--hover-border-colour, var(--border-colour));
    }

    & > input {
      position: absolute;
      width: utils.rem(30);
      height: utils.rem(30);
      opacity: 0;
      cursor: pointer;
    }
  }
}
