@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.input__label {
  @include mixins.input-label;

  &--light {
    background-color: map-get(colors.$primary-color, light);
  }

  &--disabled {
    color: map-get(colors.$secondary-color, alternate);
  }

  &--active {
    visibility: visible;
    @include mixins.transition(ease-in);

    input,
    & {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &--filled {
    visibility: visible;
    @include mixins.transition(ease-in);

    input,
    & {
      color: rgba(map-get(colors.$primary-color, primary), .8);
    }
  }

  &--error {
    color: map-get(colors.$border-color, error);
  }
}
