@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/06_components/components" as component;
@use "../../../stylesheet/00_settings/color" as colours;

.scheduling-dashboard {
  margin-left: utils.bu(15);
  $block: &;

  &__timesheet {
    width: 1240px;

    > section > dialog {
      min-width: 1240px;
      padding-left: utils.bu(9);
      padding-right: utils.bu(9);

      > header {
        height: 0;
        margin-bottom: utils.bu(2);

        > span {
          position: absolute;
          top: utils.bu(5.75);
          right: utils.bu(9);
        }
      }

      > section > div {
        min-height: 390px;
      }
    }
  }

  &__loader {
    display: flex;
    height: 100vh;
  }

  > header {
    // The 44px is the padding applied to the header consists of 20px left padding and 24px right padding
    width: calc(100vw - #{component.$scheduling-menu} - #{component.$side-menu-margin-right} - 44px);
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-color: map-get(colours.$primary-color, light);
    padding: utils.bu(3.5) utils.bu(15) utils.bu(5) utils.bu(2.5);
    left: 120px;
    box-sizing: content-box;
    height: 32px;
    z-index: 2;
    border-left: 1px solid map-get(colours.$secondary-color, variant);

    input {
      caret-color: transparent;
    }

    > .search-options {
      width: 168px;

      .dropdown-menu {
        width: 168px;
        min-width: unset;

        &__item > button > span {
          margin-right: 0;

          > span > div {
            margin-right: utils.bu(1);
          }
        }
      }

      > div > div > fieldset {
        border: unset;

        > input {
          padding-left: 0;
          font-weight: 450;
          font-size: utils.rem(20);
          line-height: utils.rem(24);
          letter-spacing: utils.rem(2);
          cursor: pointer;
        }
      }
    }
  }

  &__controls {
    display: flex;

    > .dropdown__popper {
      border: none;
      right: 90px;
      margin-top: utils.bu(1.5);

      &:last-of-type > ul > li {

        > button {
          padding-left: utils.bu(1.5);
          padding-top: utils.bu(1);
          padding-bottom: utils.bu(1);
        }

        &:not(:last-of-type) {
          border-bottom: unset;
        }
      }
    }

    > div:first-of-type {
      // Remove this rule when implementing Jira integration
      margin-right: utils.bu(2.5);
    }

    > div:not(:nth-of-type(2)) {
      border: 1px solid map-get(colours.$border-color, secondary);
      display: flex;
      align-items: center;

      #{$block}__navigation-left,
      #{$block}__navigation-right {
        padding: utils.bu(1.5) utils.bu(2);
      }

      > #{$block}__navigation-date {
        display: flex;
        height: 100%;
        border-right: 1px solid map-get(colours.$border-color, secondary);
        border-left: 1px solid map-get(colours.$border-color, secondary);
        align-items: center;
        padding-right: utils.bu(3);
        padding-left: utils.bu(3);
        cursor: pointer;
      }

      > #{$block}__sidebar-icon {
        cursor: pointer;
      }
    }

    #{$block}__sidebar-icon {
      border: 1px solid map-get(colours.$border-color, secondary);
      display: none;// Change to display: flex when integrating with Jira
      align-items: center;
      margin-left: utils.bu(2.5);
      margin-right: utils.bu(2.5);
      padding-left: utils.bu(1);
      padding-right: utils.bu(1);
      height: 100%;
      cursor: pointer;
    }

    #{$block}__allocation-icon {
      display: flex;
      cursor: pointer;
      background-color: map-get(colours.$border-color, secondary);
      padding-left: utils.bu(1.5);
      padding-right: utils.bu(1.5);
      height: 100%;
      align-self: center;

      > svg {
        color: map-get(colours.$primary-color, white);
        align-self: center;
      }
    }

    > .dropdown__popper > .dropdown-menu {
      min-width: 170px;
    }
  }
}
