@use "../../../../../stylesheet/00_settings/color" as colors;
@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/01_tools/mixin" as mixins;

.comment-input-action {
  cursor: pointer;
  color: map-get(colors.$primary-color, blue);
  font-weight: 450;
  padding-right: utils.rem(12);

  &--disabled {
    color: map-get(colors.$secondary-color, disabled);
    cursor: default;
  }

  &--error {
    color: map-get(colors.$border-color, error);
  }

  &--filled {
    cursor: pointer;
    opacity: 1;
    @include mixins.transition(all, .2s, ease-in);
  }

  &:not(&--filled) {
    cursor: pointer;
    opacity: .5;
    @include mixins.transition(all, .2s, ease-in);
  }
}
