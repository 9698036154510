@use "../../stylesheet/00_settings/util" as utils;
@use "../../stylesheet/01_tools/mixin" as mixins;
@use "../../stylesheet/00_settings/config" as config;
@use "../../stylesheet/00_settings/color" as colors;

.employee-dashboard {
  height: 100%;
  padding-left: utils.rem(30);
  padding-right: utils.rem(30);
  padding-top: utils.rem(42);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    padding: utils.bu(10) utils.rem(126) utils.bu(10) utils.bu(13);
  }

  & > h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: utils.rem(66);
    font-size: utils.rem(28);
    font-weight: 400;
    letter-spacing: utils.rem(1.6);
    line-height: utils.rem(34);
    color: map-get(colors.$primary-color, black);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(61);
      font-weight: 450;
      line-height: utils.rem(73);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: 0;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.bu(10);
      flex-direction: row;
    }

    & > .card {
      height: utils.bu(25);
      margin-bottom: utils.rem(21);
      padding-top: utils.rem(23);
      padding-left: utils.rem(28);
      padding-bottom: utils.rem(36);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        padding: utils.bu(5) utils.bu(5) utils.bu(8) utils.bu(5);
        width: utils.rem(350);
        height: utils.rem(350);
        flex-direction: row;
        margin-right: utils.bu(10);
      }

      &:last-child {
        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-right: 0;
        }
      }

      & > h5 {
        font-size: utils.rem(25);
      }

      & > a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--dark {
        padding-top: utils.bu(3);
        padding-bottom: utils.bu(2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          width: utils.rem(774);
        }

        & > small {
          margin-right: utils.rem(4);
          margin-bottom: utils.rem(20);
          font-size: utils.rem(12);
          align-self: flex-end;

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            margin-top: utils.rem(7);
            margin-bottom: 0;
          }
        }

        & > p {
          line-height: 150%;
          font-size: utils.rem(12);
          margin-bottom: utils.rem(12);

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            font-size: utils.bu(2);
            line-height: 160%;
          }
        }
      }
    }
  }

  &__counter {
    padding-top: utils.rem(65);
    display: flex;
    flex-direction: row;
    align-items: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      align-self: flex-end;
      padding-top: 0;
      align-items: start;
    }

    & > span {
      font-size: utils.rem(20);
      letter-spacing: utils.rem(1.6);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: utils.rem(25);
      }

      &:first-child {
        font-size: utils.bu(7);
        margin-right: utils.rem(9);
        line-height: 0;
        @include mixins.set-font-family("Neue Haas Grotesk Display Pro");

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-right: utils.rem(12);
          font-size: utils.bu(12);
        }
      }
    }
  }
}
