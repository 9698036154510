@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.add-user {
  padding: utils.bu(15) utils.rem(63) 0 utils.bu(7);

  & > h3 {
    font-size: utils.rem(39);
    letter-spacing: utils.rem(1.6);
  }

  & > hr {
    margin-top: utils.bu(7);
    margin-bottom: utils.bu(5);
    border: utils.rem(1) solid map-get(colors.$border-color, primary);

    &:last-of-type {
      margin-top: utils.bu(5);
    }
  }

  & > span {
    font-size: utils.rem(12);
    letter-spacing: utils.rem(1.6);
  }

  & > .form {
    margin-top: utils.bu(5);
  }

  &__actions {
    display: flex;

    & > .button + .button {
      margin-left: utils.bu(3);
    }

    & > .button:only-child {
      width: 100%;
    }
  }

  $block: &;

  &--mobile {
    padding: 0;
    background-color: map-get(colors.$primary-color, white);

    > .form {
      margin-top: 0;
      padding: utils.bu(4) utils.rem(28.5) utils.bu(4) utils.rem(28.5);
    }

    #{$block}__actions {
      padding: utils.bu(4);
      background-color: map-get(colors.$primary-color, light);
      justify-content: center;

      & > .button {
        width: utils.rem(259);
        height: utils.rem(61);
      }
    }
  }
}
