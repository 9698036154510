@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.kudos-nomination-card {
  margin-bottom: utils.bu(1);
  @include mixins.transition(background-color);

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    margin-bottom: utils.bu(3);
  }

  &__creator {
    font-weight: 700;
  }

  & > .card {
    border-radius: utils.rem(9);
    padding: utils.rem(20) utils.rem(0) utils.rem(20) utils.bu(0);
    border: none;
    cursor: default;

    & > div:nth-of-type(2) {
      margin-left: utils.rem(26);

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-left: utils.bu(12.5);
      }
    }

    &:hover {
      background-color: map-get(colors.$primary-color, white);
    }

    & > div {
      align-items: center;
      margin-top: utils.bu(3);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-left: utils.bu(12.5);
        margin-top: utils.bu(1);
      }

      > span:first-of-type {
        margin-left: utils.bu(.5);
        font-size: utils.rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: utils.rem(1.2);
      }
    }
  }

  &__threads {
    display: flex;
    border-top: utils.rem(1) solid map-get(colors.$secondary-color, variant);
    border-bottom: utils.rem(1) solid map-get(colors.$secondary-color, variant);
    margin-top: utils.bu(2);
    margin-right: utils.bu(1.5);
    margin-left: utils.rem(10);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-left: utils.bu(8.5);
    }

    & > button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: utils.bu(.5);
      margin-bottom: utils.bu(.5);
      width: 50%;
      justify-content: center;
      border-radius: utils.rem(5);
      height: utils.rem(48);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        flex-direction: row;

        &:hover {
          background-color: map-get(colors.$background-color, light);
        }
      }

      & > span {
        font-size: utils.rem(12);
        margin-top: utils.rem(5);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin: utils.bu(2) utils.bu(1.5) utils.bu(2) utils.bu(1);
          flex-direction: row;
        }
      }
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    padding-left: utils.rem(26);

    & > div {
      display: flex;
      margin-bottom: utils.bu(2);
      margin-right: 27px;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        justify-content: space-between;
      }

      & > a > img {
        height: utils.bu(6);
        width: utils.bu(6);
        margin-right: utils.rem(12);
        border-radius: map-get(extra.$border-radius, circular);
        object-fit: cover;

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          height: utils.bu(5);
          width: utils.bu(5);
          margin-right: utils.rem(28);
        }

        &:hover {
          opacity: .8;
        }
      }

      > div.dropdown__trigger > svg {
        cursor: pointer;
        margin-right: utils.bu(3);
      }
    }
  }

  > &__chevron-icon {
    margin-right: utils.bu(3);
    height: utils.rem(8);
    width: utils.rem(15);
    color: map-get(colors.$secondary-color, secondary);
    @include mixins.opacity-hover;

    &--open {
      transform: rotate(180deg);
      margin-right: utils.bu(3);
    }
  }

  @keyframes heart-beat {

    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }

  > &__heart-icon {
    width: utils.rem(16);

    &--liked {
      fill: map-get(colors.$primary-color, variant);
      animation: heart-beat .6s;
      stroke: map-get(colors.$primary-color, variant);
    }
  }

  > & .dropdown__trigger {
    height: utils.rem(16);
  }

  > & .dropdown-menu__item {
    border-bottom: none;
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;

    & > p {
      font-weight: 400;
      font-size: utils.rem(12);
      line-height: utils.bu(2);
      letter-spacing: utils.rem(1.2);

      & > a,
      & > span {
        font-weight: 700;
        font-size: utils.rem(12);
        letter-spacing: utils.rem(1.2);
      }

      & > a:hover {
        text-decoration: underline;
      }
    }

    & > time {
      font-weight: 400;
      font-size: utils.rem(12);
      margin-top: utils.rem(4);
      letter-spacing: utils.rem(1.6);
    }
  }

  &__total-reactions {
    display: flex;
    justify-content: space-between;
    margin-right: utils.rem(26);
    font-size: utils.rem(12);
    height: utils.rem(16);
    padding-left: utils.rem(26);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-right: utils.rem(47);
      padding-left: 0;
    }

    & > div > div {
      display: flex;
      flex-direction: row;
      gap: utils.bu(.5);
      align-items: center;
    }

    // X Comments

    & > div > button {
      @include mixins.transition(hover, .2s, ease-in);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__description {
    overflow: hidden;
    white-space: pre-wrap;
    padding-left: utils.rem(26);
    margin-bottom: utils.rem(20);
    max-height: utils.rem(100);
    overflow-y: scroll;

    @include mixins.transition(max-height);
    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding-left: utils.rem(0);
    }

    & > p {
      font-size: utils.rem(12);
      line-height: utils.bu(2);
      letter-spacing: utils.rem(1.6);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-left: utils.rem(68);
      margin-right: utils.rem(60);
    }
  }

  .category-tag {
    margin-top: utils.bu(2);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-left: utils.rem(92);
    }
  }
}
