@use "sass:math";
@use "../00_settings/config" as config;
@use "../00_settings/color" as colors;
@use "../00_settings/extra" as extras;
@use "../00_settings/util" as utils;

/// Single mixin to change font families
@mixin set-font-family($font-family: default) {
  @if $font-family == default {
    font-family: 'SF Pro', sans-serif;
  } @else {
    font-family: $font-family, sans-serif;
  }
}

// Combines a set of `$properties` into a transition property using `$transition-duration`
@mixin transition($properties...) {
  $transition: '';

  @each $property, $index in $properties {
    $transition: unquote($transition) + $property config.$transition-duration;

    @if index($properties, $property) < length($properties) {
      $transition: $transition + ', ';
    }
  }

  transition: $transition;
}

// Helper to output placeholder styling for each browser.
// Accepts a block of properties to add to the placeholders.
@mixin placeholder($color) {

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    @content;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    @content;
  }
}

// Helper to center a flex element vertically and horizontally
@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin input-container() {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border: utils.rem(1) solid map-get(colors.$border-color, primary);
  @include transition(border-color);
}

@mixin textarea-container() {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border: utils.rem(1) solid map-get(colors.$border-color, primary);
  @include transition(border-color);
}

// Standardised input element styles
@mixin input-content() {
  border: none;
  height: utils.bu(6);
  padding: utils.bu(2) 0 utils.bu(2) utils.bu(2);
  background-color: transparent;
  font-size: utils.rem(12);
  font-weight: 400;
  letter-spacing: utils.rem(1.6);
  color: map-get(colors.$secondary-color, secondary) !important;
  flex: 1 0 auto;
  @include set-font-family("Neue Haas Grotesk Display Pro");
  @include transition(color);
  @include placeholder(map-get(colors.$secondary-color, secondary));

  &:focus,
  &:active {
    color: map-get(colors.$primary-color, primary);
    @include placeholder(map-get(colors.$primary-color, primary)) {
      visibility: hidden;
    }
  }
}

@mixin textarea-content() {
  @include input-content;

  resize: none;
  height: utils.rem(94);
}

@mixin input-label() {
  visibility: hidden;
  position: absolute;
  top: utils.rem(-5);
  left: utils.rem(14);
  padding-left: utils.bu(.5);
  padding-right: utils.bu(.5);
  background-color: map-get(colors.$primary-color, white);
  line-height: utils.rem(10);
  color: map-get(colors.$secondary-color, alternate);
  @include transition(ease-out);
}

///  breakpoint mixins based on breakpoint definitions, accepting content blocks
///  to be used for responsive design
@mixin breakpoint($bp-name) {
  $min-width: '';

  @if map-has-key(config.$breakpoints, $bp-name) {
    $min-width: map-get(config.$breakpoints, $bp-name);
  } @else if (type_of($bp-name) == number) {
    $min-width: $bp-name;
  }

  $min-width-ems: math.div($min-width, config.$base-font-size) * 1em;

  @media screen and (min-width: $min-width-ems) {
    @content;
  }
}

@mixin opacity-hover($opacity: .5) {
  cursor: pointer;
  opacity: $opacity;
  @include transition(all, .2s, ease-in);

  &:hover {
    opacity: 1;
  }
}

@mixin card($type: '') {
  background-color: map-get(colors.$background-color, grey);
  border: utils.rem(1) solid map-get(colors.$border-color, primary);
  cursor: pointer;
  height: utils.rem(200);
  margin-bottom: utils.bu(3);
  padding: utils.bu(3) utils.bu(4) utils.bu(4.5) utils.bu(3.5);
  @include transition(background-color, .2s, ease-in);

  @if $type == 'dark' {
    background-color: map-get(colors.$primary-color, black);
    color: map-get(colors.$primary-color, white);
  }

  @if $type == 'grey' {
    background-color: map-get(colors.$background-color, light);

    &:hover {
      background-color: map-get(colors.$primary-color, white);
    }
  }

  @include breakpoint(map-get(config.$breakpoints, medium)) {
    padding: utils.bu(8) utils.bu(5) utils.bu(8) utils.bu(5);
    width: utils.rem(350);
    height: utils.rem(350);
    margin-right: utils.bu(10);
  }
}
