@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    margin-bottom: utils.rem(12);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    flex-direction: row;
    justify-content: space-between;

    & > button {
      margin-bottom: 0;
    }
  }
}
