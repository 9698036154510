@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/06_components/components" as component;
@use "../../../../stylesheet/01_tools/mixin" as mixin;
@use "../../../../stylesheet/00_settings/util" as util;
@use "../../../../stylesheet/06_components/components" as components;

#progress-loader {
  width: 100%;
  z-index: map-get(components.$z-indexes, progress-loader);
  height: 2px;
  position: fixed;
  overflow: hidden;
  margin-top: util.rem(component.$header-height) - .1rem;

  > div {
    height: 100%;
    background-color: map-get(colors.$primary-color, black);
    transition: width .2s ease-in-out;
  }

  &.full-screen {
    margin-top: 0;

    > div {
      background-color: map-get(colors.$primary-color, white);
    }
  }
}

#progress-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: map-get(components.$z-indexes, progress-loader-overlay);
  padding-top: util.bu(9);
  background-color: map-get(colors.$primary-color, light-grey);
  cursor: not-allowed;
  @include mixin.transition(opacity, .3s);

  &.show {
    opacity: .3;
  }
}
