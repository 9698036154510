.pie-chart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--grow {
    transition: all .2s ease-in-out;
    transform-origin: 50% 50%;

    &:hover {
      transform: scale(1.1);
      opacity: .9;
    }
  }
}

svg {
  overflow: visible;
}
