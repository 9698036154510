@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.form-row {
  width: 100%;
  $block: &;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
  }

  &--vertical {
    flex-direction: column;

    & > div {
      width: 100%;

      & + div {
        margin-top: utils.bu(4);
        margin-left: 0;
      }
    }
  }

  & > div + div {
    margin-top: utils.bu(3);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: 0;
      margin-left: utils.bu(7);
    }
  }

  & > div:only-child {
    width: 100%;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: calc(50% - #{utils.rem(28)});
    }
  }

  &--full-width {

    &#{$block} > div {
      width: 100%;
    }
  }

  &--no-margin {

    &#{$block} > div + div {
      margin-left: 0;
    }
  }
}
