@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extras;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.category-select {
  min-height: utils.rem(125);

  &__field-container {
    white-space: nowrap;
    padding-right: utils.rem(28);
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      overflow-x: hidden;
    }
  }

  &__field-container::-webkit-scrollbar {
    display: none;
  }

  & > div > fieldset {
    display: flex;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__label {
    display: flex;
    font-size: utils.rem(12);
    margin-bottom: utils.rem(20);
    color: map-get(colors.$secondary-color, secondary);
    letter-spacing: utils.rem(1.2);
  }

  &__label > svg {
    margin-left: utils.bu(1);
    color: map-get(colors.$primary-color, light-grey);
    @include mixins.opacity-hover(.8);
  }

  &__radio-group {
    margin-bottom: utils.rem(12);

    &:not(:last-of-type) {
      margin-right: utils.rem(12);
    }
  }

  &__tooltip {
    width: utils.rem(790);
    height: utils.rem(266);
    overflow: scroll;
  }
}
