@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("../fonts/Neue Haas Grotesk Display Pro/NHaasGroteskDSPro-45Lt.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("../fonts/Neue Haas Grotesk Display Pro/NHaasGroteskDSPro-55Rg.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("../fonts/Neue Haas Grotesk Display Pro/NHaasGroteskDSPro-65Md.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("../fonts/Neue Haas Grotesk Display Pro/NHaasGroteskDSPro-75Bd.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
