@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

#mobile-back-action {
  display: flex;
  align-items: center;
  padding-top: utils.rem(30);
  padding-bottom: utils.rem(22);

  & > span {
    font-size: utils.bu(2);
    color: map-get(colors.$background-color, dark);
    margin-left: utils.rem(10);
    line-height: 200%;
    letter-spacing: utils.rem(1.6);
  }

  & > svg {
    margin-left: utils.rem(30);
    @include mixins.opacity-hover;
  }
}
