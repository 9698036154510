@use "../../../stylesheet/00_settings/color" as color;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/util" as util;

.tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: map-get(color.$primary-color, light);
  height: util.rem(23);

  & > p {
    font-size: util.rem(12);
    margin-left: util.rem(4);
  }

  & > svg {
    height: util.rem(6);
    width: util.rem(6);
    margin-right: util.rem(10);
    margin-left: util.rem(10);
    cursor: pointer;
    @include mixins.opacity-hover;
  }
}
