@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/00_settings/extra" as extra;

.profile-image-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: utils.rem(4);
  margin-bottom: utils.rem(4);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-top: utils.rem(33);
    margin-bottom: utils.rem(63);
  }

  & > .button {
    position: absolute;
    font-size: utils.rem(12);
    top: utils.bu(-2);
    right: utils.rem(-19);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      right: 0;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      right: utils.rem(-32);
      top: utils.rem(-65);
    }
  }

  & > span {
    margin-top: utils.bu(3);
    font-size: utils.rem(12);
    letter-spacing: utils.rem(1.6);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.rem(58);
      font-size: utils.bu(2);
    }
  }

  &__image {
    position: relative;
    width: utils.bu(15);
    height: utils.bu(15);
    border-radius: map-get(extra.$border-radius, circular);
    @include mixins.opacity-hover;

    & > img {
      width: utils.bu(15);
      height: utils.bu(15);
      border-radius: map-get(extra.$border-radius, circular);
      object-fit: cover;
    }
  }

  &__action {
    position: absolute;
    right: utils.rem(7);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: utils.rem(28);
    height: utils.rem(28);
    background: map-get(colors.$background-color, white);
    border-radius: map-get(extra.$border-radius, circular);
    cursor: pointer;
  }

  &__accent-line {
    position: relative;
    right: utils.rem(39);
    margin-top: utils.rem(20);
    height: utils.rem(1);
    width: utils.rem(46);
    align-self: flex-end;
    background-color: map-get(colors.$background-color, dark);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      right: utils.rem(221);
    }
  }
}
