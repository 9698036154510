@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.category-tag {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: utils.rem(9);
  letter-spacing: utils.rem(1.6);
  padding: utils.rem(12);
  font-size: utils.rem(12);
  font-weight: 450;
  color: map-get(colors.$secondary-color, light);
  cursor: pointer;

  @include mixins.transition(all);

  & > span {

    &:first-child {
      margin-right: utils.bu(1);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-right: utils.rem(10);
      }
    }
  }

  &--outline {
    color: map-get(colors.$secondary-color, secondary);
    font-weight: 400;
  }

  &--hover:hover {
    opacity: .7;
  }

  &--disabled {
    opacity: .7;
    cursor: default;
  }
}
