@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/00_settings/extra" as extra;

.scheduling-timesheet-table {

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: utils.bu(5.5);

    > thead > tr {

      > th:not(:first-of-type) {
        padding: 0 utils.bu(3) utils.bu(2) utils.bu(3);
        text-align: center;
        max-width: 74px;
      }

      > th:first-of-type {
        padding-bottom: utils.bu(2);
        padding-right: utils.bu(3);
        width: 142px;
        font-size: utils.rem(20);
        text-align: left;
        vertical-align: bottom;
        letter-spacing: utils.rem(1.6);
      }

      > th:last-of-type {
        vertical-align: bottom;
        max-width: 60px;
        padding-left: utils.bu(3);
        padding-right: utils.bu(3);
      }
    }
  }

  &__header {

    > th:first-of-type {
      font-size: utils.rem(20);
      font-weight: 400;
    }

    > th:last-of-type {
      font-size: utils.rem(16);
      font-weight: 450;
    }

    > th {

      > div {

        > span:first-of-type {
          font-size: utils.rem(16);
          font-weight: 450;
          display: inline-block;
          margin-bottom: utils.bu(2);
        }

        > span:last-of-type {
          font-size: utils.rem(14);
          font-weight: 400;
        }
      }
    }
  }

  &__project {
    border-bottom: 1px solid map-get(colors.$secondary-color, variant);

    td {
      padding: utils.bu(3) 0;
      min-height: 16px;
    }

    td:not(:first-of-type) {
      font-weight: 350;
      color: map-get(colors.$background-color, dark-hover);
      text-align: center;
    }

    > td:first-of-type {
      display: flex;
      align-items: center;
      gap: utils.bu(1);
      letter-spacing: utils.rem(1.6);

      > div {
        min-width: 12px;
        min-height: 12px;
        border-radius: map-get(extra.$border-radius, circular);
      }
    }
  }

  &__total {

    td:first-of-type {
      font-size: utils.rem(20);
      font-weight: 400;
      padding-top: utils.bu(2);
      vertical-align: top;
      letter-spacing: utils.rem(1.6);
    }

    td:not(:first-of-type) {
      padding-top: utils.bu(2);
      text-align: center;
      font-size: utils.rem(16);
      font-weight: 450;
    }
  }
}
