@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.empty-notifications {
  width: 100%;
  margin-top: utils.rem(63);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: utils.rem(167);

    & > svg {
      color: map-get(colors.$primary-color, primary);
    }

    & > h6 {
      margin-top: utils.rem(30);
      font-size: utils.rem(20);
      letter-spacing: utils.rem(2);
    }

    & > span {
      margin-top: utils.rem(30);
      font-size: utils.rem(10);
      letter-spacing: utils.rem(2);
    }
  }
}
