@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/config" as config;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;
@use "sass:math";

$arrow-size: utils.rem(10);
$border-size: 1.5;

/* Calculate triangle top offset based on border width */
$offset: utils.rem(math.sqrt(math.pow($border-size, 2) * 2));

.tooltip {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;

  &--active {
    display: flex;
    opacity: 1;
  }

  &--full-width {
    width: calc(100vw - #{utils.bu(7)});
    margin: 0 utils.rem(28);
  }

  &__content {
    display: flex;
    opacity: 1;
    z-index: inherit;
    align-items: center;
    justify-content: center;
    min-width: utils.rem(100);
    min-height: utils.bu(4);
    background-color: white;
    transition: all .3s ease;
    border: solid utils.rem(1) map-get(colors.$secondary-color, variant);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__arrow {
    width: 0;
    height: 0;
    border-bottom: solid $arrow-size map-get(colors.$secondary-color, variant);
    border-left: solid $arrow-size transparent;
    border-right: solid $arrow-size transparent;
    position: absolute;
    top: -$arrow-size;
    left: 0;
    z-index: 2;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-bottom: solid $arrow-size white;
      border-left: solid $arrow-size transparent;
      border-right: solid $arrow-size transparent;
      bottom: (-$arrow-size - $offset);
      left: -$arrow-size;
    }

    &::before {
      content: "";
      background: map-get(colors.$secondary-color, variant);
      width: utils.rem(20);
      height: utils.rem(1);
      position: absolute;
      top: $arrow-size;
      left: -$arrow-size;
    }
  }
}
