@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.basic-profile {
  position: relative;
  top: utils.rem(20);
  padding: 0 utils.rem(30);
  display: flex;
  flex-direction: column;
  align-items: center;
  $block: &;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    padding: 0 utils.rem(100) utils.rem(31) utils.rem(100);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    top: utils.rem(135);
    padding: 0 utils.rem(50) utils.rem(31) utils.rem(50);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    padding-right: utils.rem(121);
    padding-left: utils.rem(121);
  }

  & > img {
    margin-bottom: utils.bu(3);
    width: utils.bu(10);
    height: utils.bu(10);
    border-radius: map-get(extra.$border-radius, circular);
    object-fit: cover;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-bottom: utils.bu(5);
      width: utils.rem(179);
      height: utils.rem(179);
    }
  }

  &__personal-details {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(610);
    }

    & > h5 {
      margin-bottom: utils.rem(13);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-bottom: utils.bu(2);
      }
    }

    & > a {
      position: absolute;
      top: utils.bu(1);
      right: 0;
      padding: utils.bu(.5);
      @include mixins.opacity-hover;

      & > svg {
        margin: 0;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        position: relative;
        margin-top: utils.rem(6);
        top: unset;
        left: 51%;
        bottom: utils.rem(85);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-top: 0;
        bottom: utils.rem(58);
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > small:nth-child(2) {
        margin-top: utils.rem(10);
      }
    }
  }

  &__socials {
    display: flex;
    gap: utils.rem(38);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: none;
    }
  }

  &__contact {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: utils.rem(12);
    }

    & > div {
      display: none;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        display: flex;
        flex-direction: column;
      }

      & > small {
        margin-bottom: utils.bu(1);
        margin-top: utils.bu(1);
        font-size: utils.rem(10);
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: utils.rem(12);
        color: map-get(colors.$secondary-color, hover);
      }

      & > span {
        font-size: utils.rem(12);
        letter-spacing: utils.rem(1.6);
      }

      & > span > span + span {
        margin-left: utils.bu(1);
      }
    }
  }

  &__header {
    display: none;
    position: absolute;
    right: utils.rem(30);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      right: 0;
      margin-right: utils.rem(130);
    }

    & > a {
      margin-right: utils.rem(22);
      @include mixins.opacity-hover;

      &:last-child {
        margin-right: 0;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-right: utils.rem(18);
      }
    }

    & > svg {
      cursor: pointer;
    }
  }

  &__contact-details {
    margin-top: utils.rem(18);
    display: flex;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      min-width: utils.rem(610);
      margin-top: utils.rem(7);
      justify-content: space-between;
    }

    & > div + div {

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-left: utils.bu(10);
      }
    }
  }

  &__cards {
    margin-top: utils.bu(2);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.rem(116);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-flow: row wrap;
    }

    & > .card {
      height: utils.rem(250);
      width: 100%;
      margin-bottom: utils.rem(21);
      padding-top: utils.rem(23);
      padding-left: utils.rem(28);
      padding-bottom: utils.rem(36);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        padding: utils.bu(5) utils.bu(5) utils.bu(8) utils.bu(5);
        width: utils.rem(350);
        height: utils.rem(350);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-right: utils.rem(60);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
        margin-right: utils.rem(74);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__counter {
    align-self: flex-end;

    & > span {
      font-size: utils.rem(25);
      letter-spacing: utils.rem(1.6);

      &:first-child {
        font-size: utils.bu(12);
        line-height: 0;
        @include mixins.set-font-family("Neue Haas Grotesk Display Pro");
      }
    }

    &--not-available {

      & > span:first-child {
        font-size: utils.rem(25);
        margin: 0;
      }
    }
  }

  & > p {
    width: utils.rem(305);
    margin-top: utils.rem(27);
    text-align: center;
    font-size: utils.rem(12);
    line-height: 200%;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(627);
      margin-top: utils.bu(7);
      font-size: utils.bu(2);
    }
  }

  &__contact-actions {
    width: 100%;
    height: utils.rem(75);
    margin-top: utils.rem(29);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > span {
      position: absolute;
      bottom: utils.rem(-40);
      padding: utils.rem(10);
      display: flex;
      justify-content: center;
      width: utils.rem(150);
      background-color: rgba(#525252, .5);
      border-radius: utils.rem(5);
      color: map-get(colors.$primary-color, white);
      font-size: utils.rem(10);
    }
  }
}

.contact-action {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: utils.rem(9);
  color: map-get(colors.$primary-color, black);
  background-color: map-get(colors.$background-color, contact-action);
  padding: utils.rem(18) utils.rem(34) utils.rem(17);
  width: utils.rem(100);
  margin-right: utils.rem(10);
  @include mixins.transition(width);

  &:last-child {
    margin-right: 0;
  }

  > svg {
    fill: map-get(colors.$primary-color, black);
    color: map-get(colors.$background-color, contact-action);
  }

  > span {
    font-size: utils.rem(10);
    margin-top: utils.rem(6);
    font-weight: 450;
    line-height: 150%;
  }

  &--open {
    width: 100%;
    position: absolute;
    right: 0;
    flex-direction: row;
    justify-content: space-between;
    padding-left: utils.rem(29);
    padding-right: utils.rem(13);
    @include mixins.transition(width);

    svg:nth-child(2) {
      fill: map-get(colors.$primary-color, black);
      color: map-get(colors.$background-color, contact-action);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    > span {
      font-weight: 400;
      font-size: utils.rem(12);
      line-height: 150%;
      letter-spacing: .1em;

      &:first-child {
        margin-bottom: utils.rem(6);
        font-weight: bold;
        font-size: utils.rem(14);
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      color: map-get(colors.$primary-color, black);
      margin-right: utils.bu(4);

      &:last-child {
        width: utils.rem(11);
        height: utils.rem(11);
        margin-right: 0;
      }
    }
  }
}
