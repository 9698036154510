@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  & > .card {
    margin-right: utils.rem(20);
    margin-left: utils.rem(20);
    padding-left: utils.rem(20);
    padding-right: utils.rem(20);
    padding-bottom: utils.bu(13);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin: unset;
      padding-left: utils.bu(5);
      padding-right: utils.bu(5);
      padding-bottom: utils.bu(8);
    }
  }
}
