@use "../../../../../stylesheet/00_settings/util" as utils;
@use "../../../../../stylesheet/00_settings/color" as colors;

.client-add-form {
  position: relative;
  margin-left: utils.bu(15);
  margin-right: utils.bu(15);
  top: 135px;

  > form > fieldset {
    background-color: map-get(colors.$primary-color, white);
    padding: utils.bu(8) utils.bu(9) utils.bu(6.5);

    .form-row:not(:last-of-type) {
      margin-bottom: utils.bu(4);
    }
  }

  > form > fieldset > .form-row > div {
    width: 100%;
  }

  > &__actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    > div {
      width: unset;
    }
  }
}
