@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extra;
@use "../../../stylesheet/06_components/components" as component;

#timesheet-loader {
  display: flex;
  height: 100vh;
}

.scheduling-timesheet {
  position: relative;
  top: 135px;
  margin-left: utils.bu(5);
  background-color: map-get(colors.$secondary-color, light);
  padding: utils.bu(5) utils.bu(9);
  min-width: 1200px;
  height: auto;
  margin-right: utils.bu(10);

  > div:first-of-type {
    display: flex;
    align-items: center;
  }

  &__status {
    border: 1px solid map-get(colors.$border-color, variant);
    border-radius: 9px;
    padding: utils.bu(1.5);
    margin-left: utils.bu(5.5);
    letter-spacing: utils.rem(1.2);
    font-size: utils.rem(12);
    color: map-get(colors.$primary-color, variant);
  }

  &__actions {
    display: flex;
    gap: utils.bu(3);
    padding-top: utils.bu(5.5);
  }

  &__modal .modal-layout__header-title {
    margin-left: 0;
    margin-top: 0;
    font-size: utils.rem(24);
    font-weight: 400;
  }

  &__modal-content > div:first-of-type {
    margin-top: utils.bu(3);
  }

  &__reject-actions {
    margin-top: utils.bu(5);
    display: flex;
    justify-content: space-between;
  }
}
