@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.filter-layout {
  padding-bottom: utils.bu(1);

  &__mobile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: utils.rem(50);
    width: utils.bu(6);
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);

    > span {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      border: 1px solid map-get(colors.$primary-color, primary);
      display: flex;
      align-items: center;
      background-color: map-get(colors.$background-color, light-grey);
      position: absolute;
      justify-content: center;
      margin-bottom: utils.bu(5);
      margin-left: utils.bu(5.5);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: utils.rem(50);

    & > section {
      display: flex;
      align-items: center;
      width: #{map-get(config.$breakpoints, medium)}px;
      padding-right: utils.rem(36);

      & > .input {
        min-width: 241px;
        margin-right: utils.bu(3);
        border: utils.rem(1) solid map-get(colors.$primary-color, primary);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          width: utils.bu(45);
          height: utils.rem(50);
        }

        & > fieldset {
          border: none;
        }
      }

      & > .dropdown__trigger > button > span {
        flex-direction: row;

        > span {
          margin-left: utils.bu(1);
          display: flex;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          border: 1px solid map-get(colors.$primary-color, primary);
          justify-content: center;
          align-items: center;
        }
      }

      .button {
        width: utils.bu(13);
      }
    }
  }
}
