@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.filters-wrapper {
  display: flex;
  flex-direction: column;
  width: 166px;
  background-color: map-get(colors.$secondary-color, light);
  border: utils.rem(1) solid map-get(colors.$border-color, secondary);

  > button {
    align-self: center;
    margin-bottom: utils.bu(4);
    margin-top: utils.bu(2.5);
    font-weight: 450;
  }
}
