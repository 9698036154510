/* stylelint-disable no-invalid-position-at-import-rule */
@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/00_settings/extra" as extra;
@import "~react-datepicker/dist/react-datepicker.min.css";

.datepicker {
  width: 100%;

  &__calendar {
    background-color: map-get(colors.$primary-color, white);
    border: utils.rem(1) solid map-get(colors.$border-color, secondary);

    .react-datepicker {

      &__header {
        border: none;
        padding-top: 0;

        &__dropdown {
          background-color: map-get(colors.$background-color, white);
          color: rgba(map-get(colors.$primary-color, white), .87);
          font-weight: 274;
          padding-bottom: utils.rem(10);
          height: utils.rem(1);
          border: none;
        }

        &.react-datepicker-year-header {
          font-weight: 400;
          font-size: utils.rem(12);
          background-color: map-get(colors.$background-color, white);
          color: map-get(colors.$primary-color, primary);

          &--hasYearDropdown {
            margin-top: utils.rem(1);
          }
        }
      }

      &__current-month {
        font-weight: 400;
        font-size: utils.rem(12);
        background-color: map-get(colors.$background-color, white);
        color: map-get(colors.$primary-color, primary);

        &--hasYearDropdown {
          margin-top: utils.rem(1);
        }
      }

      &__day-names {
        padding-top: utils.rem(15);
        padding-bottom: utils.rem(4);
        background-color: map-get(colors.$primary-color, white);
        text-transform: uppercase;
        border-bottom: utils.rem(1) dashed map-get(colors.$primary-color, primary);
      }

      &__day-name {
        font-size: utils.rem(12);
        font-weight: 400;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: utils.rem(27);
        width: utils.rem(17);
        height: utils.rem(17);
        border-radius: map-get(extra.$border-radius, circular);
        box-shadow: 0 utils.rem(1) utils.rem(4) rgba(map-get(colors.$primary-color, black), .12);

        &-icon {
          font-size: utils.bu(2);

          &::before {
            border-color: map-get(colors.$secondary-color, secondary);
            border-width: utils.rem(1) utils.rem(1) 0 0;
            top: utils.rem(7);
            height: utils.rem(6);
            width: utils.rem(6);
          }

          &--previous::before {
            right: utils.rem(-2);
          }

          &--next::before {
            left: utils.rem(-3);
          }
        }

        &--previous {
          left: utils.bu(3);
        }

        &--next {
          right: utils.bu(3);
        }

        &--years {

          &-upcoming {
            display: none;
          }

          &-previous {
            display: none;
          }
        }
      }

      &__year-dropdown,
      &__year-dropdown--scrollable {
        background-color: map-get(colors.$primary-color, white);
        font-weight: 400;
        border-radius: 0;
        color: map-get(colors.$primary-color, primary);
      }

      &__year-option:hover {
        background-color: rgba(map-get(colors.$primary-color, primary), .12);
      }

      &__year-wrapper {
        align-items: center;
        justify-content: center;
      }

      &__year-text--selected {
        background-color: map-get(colors.$primary-color, primary);
      }

      &__day {

        &--keyboard-selected {
          color: map-get(colors.$primary-color, primary);
        }

        &--today {
          background-color: rgba(map-get(colors.$primary-color, primary), .7);
          color: map-get(colors.$primary-color, white);
        }

        &--selected {
          background-color: map-get(colors.$primary-color, primary);
          color: map-get(colors.$primary-color, white);
        }
      }

      &__month {
        margin: utils.bu(1) 0 0 0;

        &-container {
          padding: utils.rem(30) utils.bu(3) utils.rem(20) utils.bu(3);
        }
      }

      &__year-read-view {

        &--selected-year {
          display: none;
        }

        &--down-arrow {
          top: utils.rem(-23);
          left: utils.rem(50);
          width: utils.rem(6);
          height: utils.rem(6);
          border-color: map-get(colors.$secondary-color, secondary);
          border-width: utils.rem(1) utils.rem(1) 0 0;
        }
      }
    }

    &.react-datepicker {
      border-radius: 0;
    }

    &-day {
      background-color: map-get(colors.$primary-color, white);
      font-weight: 400;
      border-radius: 0;

      &:hover {
        background-color: rgba(map-get(colors.$primary-color, primary), .12);
        color: rgba(map-get(colors.$primary-color, black), .87);
        border-radius: 0;
      }

      &--today {
        background-color: map-get(colors.$primary-color, primary);
        font-weight: 400;
        border-radius: 0;

        &:hover {
          background-color: map-get(colors.$primary-color, primary);
          border-radius: 0;
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
}
