@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/util" as utils;
@use "../../../stylesheet/01_tools/mixin" as mixins;
@use "../../../stylesheet/06_components/components" as component;

.side-portal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: map-get(colors.$background-color, light);
  z-index: map-get(component.$z-indexes, side-portal);
  display: none;
  transform: translateX(-100%);
  transition: transform 1000ms;

  @include mixins.breakpoint(large) {
    width: utils.rem(720);
  }

  &--open {
    transform: translateX(0%);
    display: block;
  }

  &__overlay {
    position: absolute;
    top: utils.rem(component.$header-height);
    width: 100vw;
    height: 100vh;
    transition: opacity 1000ms;
    display: none;

    @include mixins.breakpoint(large) {
      top: 0;
    }

    &--open {
      z-index: map-get(component.$z-indexes, side-portal-overlay); // place over overlay
      background-color: rgba(map-get(colors.$background-color, dark), .8);
      display: block;
    }
  }

  &__content {
    background-color: map-get(colors.$background-color, light);
  }
}
