@use "../../../../stylesheet/00_settings/color" as colors;
@use "../../../../stylesheet/00_settings/util" as utils;
@use "../../../../stylesheet/01_tools/mixin" as mixins;

.comment-input {
  position: relative;
  width: 100%;
  $block: &;

  &__wrapper {
    width: 100%;
    background-color: map-get(colors.$primary-color, light);
    border-radius: utils.rem(9);

    > .input__label {
      background-color: map-get(colors.$primary-color, light);
    }

    > .input__action {
      color: map-get(colors.$primary-color, blue);
    }

    > textarea {
      @include mixins.input-content;

      resize: none;
      overflow-y: hidden;
      height: 2rem;
      padding: utils.bu(1) 0 utils.bu(2) utils.bu(2);
    }

    > p {
      font-size: utils.rem(12);
      font-weight: 400;
      letter-spacing: utils.rem(1.6);
      padding: utils.bu(1) 0 utils.bu(2) utils.bu(2);
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      @include mixins.set-font-family("Neue Haas Grotesk Display Pro");
    }

    @include mixins.input-container;
  }

  &--filled {
    #{$block}__wrapper {
      border: utils.rem(1) solid rgba(map-get(colors.$border-color, secondary), .8);
    }
  }

  &--error {
    #{$block}__wrapper {
      border: utils.rem(1) solid map-get(colors.$border-color, error);
    }

    & input,
    input:focus {
      color: map-get(colors.$border-color, error);
      @include mixins.placeholder(map-get(colors.$primary-color, error));
    }
  }

  &--resizable {

    #{$block}__wrapper {

      > textarea {
        resize: vertical;
      }
    }
  }

  #{$block}__wrapper {
    border: none;
  }
}
